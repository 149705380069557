import React, { useEffect, useState } from "react";
import styled from "styled-components";
import AcceptTermsFooter from "../../Footer/AcceptTermsFooter";
import { validatePhoneNumber } from "../../../../utils/validation";
import { strings } from "../../../../utils/localization";
import DatePicker from "react-date-picker";
import TimePicker from "react-time-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-time-picker/dist/TimePicker.css";

import "../../../../styles/LiveChatStyle.css";
import WeGotYourMessage from "./WeGotYourMessage";
import ModernPhoneNumberField from "../../../../components/Fields/ModernPhoneNumberField";
import ModernInput from "../../Input/ModernInput";
import ModerSubmitButton from "../../../../components/Buttons/ModerSubmitButton";

const Container = styled.form`
  max-width: 400px;
  margin: 0 auto;

  .matadot-live-chat-123789-main-input {
    width: 100%;
    height: 46px;
    border-radius: 5px !important;
  }

  .flag-dropdown {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
`;

const Note = styled.p`
  margin-top: -5px;
  margin-bottom: 5px;
  font-size: 12px;
  color: #c43423;
  margin-bottom: 2px;
`;

const FormContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;

const Form = styled.div`
  border-radius: 14px 14px 0px 14px;
  margin-bottom: 10px;

  .matadot-live-chat-123789-main-input {
    background-color: #f9f9f9;
    border-bottom-width: 1.3px;
    border-top: 0px;
    border-right: 0px;
    border-radius: 0px;
    color: #17181b;
  }

  .matadot-live-chat-123789-main-input:focus {
    border-bottom-color: ${({ primaryColor }) => primaryColor};
  }
`;

const RightContent = styled.div`
  background-color: #fafbfd;
  padding: 12px;
  border-radius: 16px;
  max-width: 80%;
`;

const StyledDatePicker = styled(DatePicker)`
  width: 100%;
  margin-top: 12px;

  .react-date-picker__wrapper {
    border: none;
    border-bottom: 1.3px solid #ccc;
    background-color: #f9f9f9;
    padding: 0 12px;
    height: 46px;
    display: flex;
    align-items: center;
  }

  .react-date-picker__inputGroup__input {
    font-size: 16px;
    color: #17181b;
  }

  .react-date-picker__calendar {
    z-index: 1000;
  }
`;

const StyledTimePicker = styled(TimePicker)`
  width: 100%;
  margin-top: 12px;

  .react-time-picker__wrapper {
    border: none;
    border-bottom: 1.3px solid #ccc;
    background-color: #f9f9f9;
    padding: 0 12px;
    height: 46px;
    display: flex;
    align-items: center;
  }

  .react-time-picker__inputGroup__input {
    font-size: 16px;
    color: #17181b;
  }

  .react-time-picker__clock {
    z-index: 1000;
  }
`;

const SimpleLeadForm = ({
  chatData,
  submit,
  selectedQuestion,
  name,
  setName,
  phoneNumber,
  setPhoneNumber,
  firstAndLastNameSeparately,
  firstName,
  lastName,
  setFirstName,
  setLastName,
  viewIndex,
  isCustomSubmitForm,
  img,
  second_message,
  organization_name,
  color,
  answer,
  dateQuestion,
  questionType,
  typingThree,
  goBack,
  currentUrl,
}) => {
  const [isFormValid, setIsFormValid] = useState(false);
  const [mobileNumberError, setMobileNumberError] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [appointmentDate, setAppointmentDate] = useState(null);
  const [appointmentTime, setAppointmentTime] = useState(null);

  const { isCustomerNameRequired } = chatData || {};
  let backgroundColor = chatData && chatData.color ? chatData.color : "red";

  useEffect(() => {
    setIsFormValid(!mobileNumberError && phoneNumber && checkNameValidation());
  }, [mobileNumberError, phoneNumber, firstName, lastName, name]);

  useEffect(() => {
    setUsersInfo();
  }, []);

  const setUsersInfo = () => {
    if (chatData?.firstAndLastNameSeparately) {
      const firstName = localStorage.getItem("firstName") || "";
      const lastName = localStorage.getItem("lastName") || "";
      setFirstName(firstName);
      setLastName(lastName);
    } else {
      const name = localStorage.getItem("name") || "";
      setName(name);
    }
    const phoneNumber = localStorage.getItem("phoneNumber") || "";
    setPhoneNumber(phoneNumber);
  };

  const handleNameChange = (value) => {
    setName(value);
  };

  const handleMobileNumberChange = (value) => {
    setPhoneNumber(value);
    validatePhone({ value });
  };

  const checkNameValidation = () => {
    let valid = true;

    if (isCustomerNameRequired) {
      if (firstAndLastNameSeparately) {
        valid = firstName.trim() !== "" && lastName.trim() !== "";
      } else {
        valid = name.trim() !== "";
      }
    }

    if (!valid) {
      setTimeout(() => {
        scrollToBottom();
      }, 100);
    }

    return valid;
  };

  const handleSubmit = (e) => {
    const isNameValid = checkNameValidation();

    setFormSubmitted(true);
    e.preventDefault();
    if (!isFormValid || !isNameValid) return;

    // Combine date and time into a single Date object
    const date = new Date(appointmentDate);
    //const [hours, minutes] = appointmentTime.split(":").map(Number);
    //date.setHours(hours);
    //date.setMinutes(minutes);

    submit({
      viewIndex: 3,
      send: true,
      //appointmentDateTime: date.toISOString(),
    });
  };

  const validatePhone = ({ value }) => {
    const text = value || phoneNumber;
    const isMobileNumberValid = text.trim() !== "" && validatePhoneNumber(text);
    setMobileNumberError(!isMobileNumberValid);
  };

  const getPhoneNumberFromClipboard = (event) => {
    let pasteValue = (event.clipboardData || window.clipboardData).getData(
      "text"
    );
    pasteValue = pasteValue.replace(/-|\s/g, "");
    pasteValue = pasteValue.replace("(", "");
    pasteValue = pasteValue.replace(")", "");
    pasteValue = pasteValue.replace("tel:", "");
    pasteValue = pasteValue.replace("+", "");

    if (chatData?.organization_country_code?.toLowerCase() === "au") {
      if (pasteValue.length === 9) {
        pasteValue = `0${pasteValue}`;
      }
      if (pasteValue.length >= 11) {
        pasteValue = `0${pasteValue && pasteValue.slice(2)}`;
      }
    } else {
      if (pasteValue.length === 10) {
        pasteValue = `1${pasteValue}`;
      }
      if (pasteValue.length === 11) {
        pasteValue = `1${pasteValue && pasteValue.slice(1)}`;
      }
    }

    validatePhone({ value: pasteValue });
    setPhoneNumber(pasteValue);
  };

  const scrollToBottom = () => {
    const el = document.getElementById("matador-connect-main-content");
    if (el) {
      el.scrollTop = el.scrollHeight;
    }
  };

  const renderName = () => {
    if (firstAndLastNameSeparately) {
      return (
        <>
          <ModernInput
            placeholder={strings.FIRST_NAME}
            color={backgroundColor}
            value={firstName}
            backgroundColor="#fff"
            onChange={(value) => setFirstName(value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSubmit(e);
              }
            }}
            onFocus={scrollToBottom}
            required
            enableValidation={isCustomerNameRequired}
            formSubmitted={formSubmitted}
            borderDisabled
            marginBottom={12}
          />
          <ModernInput
            placeholder={strings.LAST_NAME}
            color={backgroundColor}
            value={lastName}
            backgroundColor="#fff"
            onChange={(value) => setLastName(value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSubmit(e);
              }
            }}
            onFocus={scrollToBottom}
            required
            enableValidation={isCustomerNameRequired}
            formSubmitted={formSubmitted}
            borderDisabled
          />
          <div style={{ height: 8 }}></div>
        </>
      );
    }

    return (
      <>
        <ModernInput
          placeholder={strings.FULL_NAME}
          color={backgroundColor}
          value={name}
          backgroundColor="#fff"
          onChange={handleNameChange}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSubmit(e);
            }
          }}
          onFocus={scrollToBottom}
          required
          enableValidation={isCustomerNameRequired}
          formSubmitted={formSubmitted}
          borderDisabled
          marginBottom={12}
        />
      </>
    );
  };

  const renderContent = () => {
    if (viewIndex === 555) {
      return (
        <WeGotYourMessage
          typingThree={typingThree}
          img={img}
          organization_name={organization_name}
          color={color}
          second_message={second_message}
          backgroundColor={backgroundColor}
          secondaryColor={chatData.secondaryColor}
        />
      );
    }

    return (
      <FormContainer>
        <RightContent>
          <Form primaryColor={chatData.color}>
            {renderName()}

            <ModernPhoneNumberField
              value={phoneNumber}
              onChange={(value) => handleMobileNumberChange(value)}
              defaultCountryFlag={
                chatData?.organization_country_code?.toLowerCase() || "us"
              }
              getPhoneNumberFromClipboard={getPhoneNumberFromClipboard}
              token={chatData?._token}
            />

            {formSubmitted && mobileNumberError && (
              <Note style={{ marginTop: 3, marginBottom: 5 }}>
                {strings.REQUIRED_FIELD}
              </Note>
            )}
          </Form>

          <AcceptTermsFooter
            color={chatData.termsLink}
            termsLink={chatData.termsLink}
            margin={0}
            locationId={chatData._location_id}
            currentUrl={currentUrl}
          />
          <ModerSubmitButton
            disabled={!isFormValid}
            marginTop={18}
            color={chatData.color}
            secondaryColor={chatData.secondaryColor}
          />
        </RightContent>
      </FormContainer>
    );
  };

  // return (
  //   <Container onSubmit={handleSubmit}>
  //     <Header>
  //       <ChatHeader goBack={goBack} />
  //     </Header>
  //     <InitialResponse
  //       img={img}
  //       dateQuestion={dateQuestion}
  //       selectedQuestion={selectedQuestion}
  //       answer={answer}
  //       firstMessage={first_message}
  //       questionType={questionType}
  //       primaryColor={chatData.color}
  //       isCustomSubmitForm={isCustomSubmitForm}
  //       secondaryColor={chatData.secondaryColor}
  //     />

  //     {renderContent()}
  //   </Container>
  // );

  return <Container onSubmit={handleSubmit}>{renderContent()}</Container>;
};

export default SimpleLeadForm;
