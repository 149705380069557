import React from "react";
import styled from "styled-components";
import { strings } from "../../../utils/localization";

import { ReactComponent as NewLogo } from "./assets/new_logo.svg";

const Container = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 100%;
  justify-content: center;
  background-color: #f6f7fb;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
`;

const Label = styled.span`
  font-weight: 500;
  font-size: 10px;
  line-height: 130%;
  color: #222222;
  margin-right: 5px;
`;

const EmptyContainer = styled.div`
  height: 5px;
  background-color: #f6f7fb;
`;

const DefaultFooter = ({ token }) => {
  if (token === "8c6c92ccee38405b378616e404a2009e90022a8f")
    return <EmptyContainer />;

  return (
    <Container
      target="_blank"
      aria-label="Matador"
      href="http://matador.ai/?utm_source=matador%20connect%20widget&utm_medium=link&utm_campaign=widget%20referral"
    >
      <Label>{strings.POWERED_BY}</Label>
      <NewLogo />
    </Container>
  );
};

export default DefaultFooter;
