import React from "react";
import styled from "styled-components";
import { ReactComponent as BackIcon } from "./assets/back-icon.svg";
import { ReactComponent as CloseIcon } from "./assets/close-icon.svg";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  background-color: #fff;
  z-index: 1000;
`;

const Back = styled.div`
  cursor: pointer;
  display: flex;
`;

const CloseContainer = styled.div`
  width: 32px;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const ChatHeader = ({ goBack }) => {
  const closeMatdorConnect = () => {
    window.top.postMessage("close_matador_connect", "*");
  };

  const closeIcon = () => {
    return (
      <CloseContainer onClick={closeMatdorConnect}>
        <CloseIcon height={15} width={15} stroke={"#A9A9A9"} />
      </CloseContainer>
    );
  };

  return (
    <Container>
      <Back onClick={goBack}>
        <BackIcon />
      </Back>
      <span></span>
      {closeIcon()}
    </Container>
  );
};

export default ChatHeader;
