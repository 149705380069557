import React from 'react';

const Iconset = {
    load: (
        <svg width="200px" height="200px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" className="lds-rolling">
            <circle cx="50" cy="50" fill="none" ng-attr-stroke="{{config.color}}" ng-attr-stroke-width="{{config.width}}" ng-attr-r="{{config.radius}}" ng-attr-stroke-dasharray="{{config.dasharray}}" stroke="#706AFF" strokeWidth="10" r="35" strokeDasharray="164.93361431346415 56.97787143782138" transform="rotate(93.6634 50 50)">
            </circle>
        </svg>
    ),
}

const PageLoader = (props) => {
        return (
        <div className="page-loader">
            <div className="spin">{Iconset.load}</div>
        </div>
    );
}

export default PageLoader;