import React from "react";

import styled from "styled-components";
import { SVGSendIcon } from "../Icons/Icons";

const Wrapper = styled.div`
  background: ${(props) => (props.disabled ? "#D1D2DE" : "#706AFF")};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 36px;
  width: 36px;
`;

const NextButton = ({ onClick, disabled }) => {
  return (
    <Wrapper onClick={onClick} disabled={disabled}>
      <SVGSendIcon />
    </Wrapper>
  );
};

export default NextButton;
