import { invalidPhoneNumbers } from "./invalidPhoneNumbers";

export function validatePhoneNumber(value) {
  if (invalidPhoneNumbers.includes(value)) {
    return false;
  }
  if (value?.startsWith("04")) {
    return value.length === 10;
  }
  return value?.length === 11;
}

export function validateName(value) {
  return value?.length > 0 && value?.length <= 50;
}

export function validateEmail(value) {
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  return emailPattern.test(value);
}
