import React from 'react'
import styled from 'styled-components'
import SubmitText from '../Buttons/SubmitText'

const InputField = styled.input`
  width: 100%;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  border: 1px solid #efefef;
  font: normal normal normal 15px/20px Roboto;
  letter-spacing: 0px;
  color: #747b95;
  padding-right: 10px;
  height: ${(props) => (props.theme === 'default' ? '50px' : '30px')};
  border-radius: ${(props) => (props.theme === 'default' ? '27px' : '4px')};
  padding-left: ${(props) => (props.theme === 'default' ? '21px' : '10px')};

  &:focus {
    outline: none;
    box-shadow: none;
  }
`

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  position: relative;
`

const TextInput = (props) => {
  const { theme, onSubmit, enableSubmit, primaryColor, buttonText } = props

  const button = theme === 'default' && (
    <SubmitText
      backgroundColor={primaryColor}
      onClick={onSubmit}
      enableSubmit={enableSubmit}
      text={buttonText}
    />
  )

  return (
    <Wrapper>
      <InputField {...props} />
      {button}
    </Wrapper>
  )
}

export default TextInput