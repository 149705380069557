import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import notificationSound from "./assets/notification.wav";

axios.defaults.baseURL = "https://ai-chat-api-production.up.railway.app/api/";

const ChatContext = createContext();

export const useChatContext = () => useContext(ChatContext);

const formatTimestamp = (date) => {
  const options = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };
  return new Intl.DateTimeFormat("default", options)
    .format(date)
    .replace(",", "");
};

export const ChatProvider = ({ children }) => {
  const [chatId, setChatId] = useState(() => {
    const savedChatId = localStorage.getItem("chatId");
    return savedChatId || null;
  });

  const [messages, setMessages] = useState(() => {
    const savedMessages = localStorage.getItem("messages");
    return savedMessages ? JSON.parse(savedMessages) : [];
  });

  const [isSending, setIsSending] = useState(() => {
    const savedIsSending = localStorage.getItem("isSending");
    return savedIsSending === "true";
  });

  const [logs, setLogs] = useState([]);

  const getUserId = () => {
    let storedUserId = localStorage.getItem("userId");
    if (!storedUserId) {
      storedUserId = crypto.randomUUID();
      localStorage.setItem("userId", storedUserId);
    }
    return storedUserId;
  };

  useEffect(() => {
    if (chatId) {
      localStorage.setItem("chatId", chatId);
    }
  }, [chatId]);

  useEffect(() => {
    localStorage.setItem("messages", JSON.stringify(messages));
  }, [messages]);

  useEffect(() => {
    localStorage.setItem("isSending", isSending);
  }, [isSending]);

  const initializeChat = async () => {
    const existingChatId = localStorage.getItem("chatId");
    if (!existingChatId) {
      setChatId(uuidv4());
    }
  };

  useEffect(() => {
    const handleStorage = (event) => {
      if (event.key === "messages" && event.newValue) {
        const updatedMessages = JSON.parse(event.newValue);
        if (Array.isArray(updatedMessages)) {
          setMessages(updatedMessages);
        }
      }
      if (event.key === "chatId" && event.newValue) {
        setChatId(event.newValue);
      }
      if (event.key === "isSending") {
        setIsSending(event.newValue === "true");
      }
    };

    window.addEventListener("storage", handleStorage);
    return () => window.removeEventListener("storage", handleStorage);
  }, []);

  const playNotificationSound = () => {
    const audio = new Audio(notificationSound);
    audio.play();
  };

  const setLoading = (value) => {
    setIsSending(value);
    localStorage.setItem("isSending", true);
  };

  const sendMessage = async ({ text, url }) => {
    const userId = getUserId();
    if (!chatId) {
      initializeChat().then(() => {
        sendMessage({ text });
      });
    }
    if (!text.trim()) return;
    const userMessage = {
      text,
      role: "user",
      timestamp: formatTimestamp(new Date()),
      _id: uuidv4(),
    };
    setMessages((prev) => [...prev, userMessage]);
    setLoading(true);

    try {
      const response = await axios.post("/messages", {
        message: text,
        chatId,
        userId,
        url,
      });

      if (response.data.success) {
        const { message, type, inventory, _id } = response.data.response;
        playNotificationSound();

        const botMessages = [
          {
            _id,
            text: message,
            type,
            inventory,
            role: "assistant",
          },
        ];
        setMessages((prev) => [...prev, ...botMessages]);
        setLogs((prevLogs) => [...prevLogs, { ...response.data }]);
      } else {
        console.error("Error in response", response.data);
      }
    } catch (error) {
      console.error("Error sending message", error);
    } finally {
      setLoading(false);
    }
  };

  const clearChat = () => {
    setMessages([]);
    setLogs([]);
    localStorage.removeItem("chatId");
    localStorage.removeItem("messages");
    localStorage.removeItem("isSending");
    setChatId("");
    setLoading(false);
  };

  const value = {
    messages,
    sendMessage,
    isSending,
    chatId,
    logs,
    clearChat,
    initializeChat,
  };

  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
};
