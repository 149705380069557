import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { strings } from "../../../utils/localization";
import ImagePreview from "./ImagePreview";
import Story from "./Story";
import VideoPlayer from "./VideoPlayer";

const COOKIE_WATCHED_VIDEOS = "watchedStoryVideoURLs";

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  background: linear-gradient(
    to bottom,
    ${(props) => props.backgroundColor} 0% 38%,
    #f6f7fb 38% 100%
  );

  .story_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    flex: 0;
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const Content = styled.div`
  display: flex;
  background-color: #fff;
  border-radius: 12px;
  margin: 0px 20px;
  width: 100%;
  padding: 12px 16px;
  box-shadow: 2px 2px 15px 0px rgba(83, 85, 128, 0.1);
  flex-direction: column;
`;

const Label = styled.p`
  font-size: 14px;
  line-height: 18px;
  color: #474657;
  margin: 0;
  margin-bottom: 10px;
  font-weight: 700;
  font-style: normal;
`;

const Items = styled.div`
  display: flex;
`;

const Stories = ({ token, backgroundColor, stories }) => {
  const [activeStory, setActiveStory] = useState();
  const [watchedVideos, setWatchedVideos] = useState([]);

  let mainLanguage = strings.getLanguage();
  if (mainLanguage === "es") {
    mainLanguage = "sp";
  }

  const nextStory = () => {
    const currentIndex = stories.findIndex((x) => x.id === activeStory.id);
    if (stories[currentIndex + 1]) {
      return setActiveStory(stories[currentIndex + 1]);
    }
    setActiveStory(stories[0]);
  };

  const previousStory = () => {
    const currentIndex = stories.findIndex((x) => x.id === activeStory.id);
    if (stories[currentIndex - 1]) {
      return setActiveStory(stories[currentIndex - 1]);
    }
    setActiveStory(stories[stories.length - 1]);
  };

  const getWatchedVideosFromStorage = () => {
    try {
      const data = JSON.parse(localStorage.getItem(COOKIE_WATCHED_VIDEOS));
      return data || [];
    } catch (error) {
      return null;
    }
  };

  const isVideoWatched = (id) => {
    return watchedVideos?.includes(id);
  };

  const addWatchedVieoToStorage = (id) => {
    const existingItems = getWatchedVideosFromStorage();
    if (existingItems !== null) {
      existingItems.push(id);
      try {
        localStorage.setItem(
          COOKIE_WATCHED_VIDEOS,
          JSON.stringify(existingItems)
        );
      } catch (error) {}
    }
  };

  const refreshWatchedVideos = () => {
    let items = getWatchedVideosFromStorage();
    if (items === null) {
      items = watchedVideos;
    }
    setWatchedVideos(items || []);
  };

  const addVideoToWatchedList = (id) => {
    if (isVideoWatched(id)) return;
    setWatchedVideos([...watchedVideos, id]);
    addWatchedVieoToStorage(id);
  };

  const onPlay = async (story) => {
    setActiveStory(story);
    addVideoToWatchedList(story.id);
  };

  const closePreview = () => {
    setActiveStory(null);
  };

  const getTitleValue = (title) => {
    const data = title.find((x) => x.language === mainLanguage);

    if (data) {
      return data.text;
    }

    return "";
  };

  const renderStories =
    stories &&
    stories.map((item) => {
      const { id, thumbnailUrl, imageUrl, title } = item;

      return (
        <Story
          key={id}
          title={getTitleValue(title)}
          watched={isVideoWatched(id)}
          thumbnailUrl={thumbnailUrl}
          onPlay={onPlay}
          story={item}
          imageUrl={imageUrl}
        />
      );
    });

  useEffect(() => {
    refreshWatchedVideos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    refreshWatchedVideos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStory]);

  if (!stories || !stories.length) {
    return null;
  }

  const renderPreview = () => {
    const getTitleValue = (title) => {
      const data = title.find((x) => x.language === mainLanguage);

      if (data) {
        return data.text;
      }

      return "";
    };

    if (activeStory?.videoUrl) {
      return (
        <VideoPlayer
          videoUrl={activeStory.videoUrl}
          title={getTitleValue(activeStory.title)}
          thumbnailUrl={activeStory.thumbnailUrl}
          closeVideo={closePreview}
          nextStory={nextStory}
          previousStory={previousStory}
          stories={stories}
          activeStory={activeStory}
        />
      );
    }

    if (activeStory?.imageUrl) {
      return (
        <ImagePreview
          title={getTitleValue(activeStory.title)}
          thumbnailUrl={activeStory.thumbnailUrl}
          imageUrl={activeStory.imageUrl}
          close={closePreview}
          nextStory={nextStory}
          previousStory={previousStory}
          stories={stories}
          activeStory={activeStory}
        />
      );
    }
  };

  return (
    <div>
      <Container backgroundColor={backgroundColor}>
        <Content>
          <Label>{strings.VIEW_OUR_STORIES}</Label>
          <Items>{renderStories}</Items>
        </Content>
      </Container>
      {renderPreview()}
    </div>
  );
};

export default Stories;
