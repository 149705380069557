import React from "react";
import { strings } from "../../utils/localization";
import { Iconset } from "../Icons/Icons";

const SuccessMessage = ({
  typingThree,
  img,
  organization_name,
  color,
  second_message,
  backgroundColor,
}) => {
  return (
    <div className="matadot-live-chat-123789-chat-message-wrappper matadot-live-chat-123789-left matadot-live-chat-123789-get-message">
      {typingThree ? (
        <div className="matadot-live-chat-123789-dotsContainer">
          {Iconset.typing}
        </div>
      ) : (
        <div
          id="matadot-live-chat-123789-get-message"
          className="matadot-live-chat-123789-message-with-image"
        >
          <div className="matadot-live-chat-123789-img-wrapper">
            {img ? (
              <img className="matadot-live-chat-123789-img" src={img} alt="Success" />
            ) : (
              Iconset.noImage
            )}
          </div>
          <div className="matadot-live-chat-123789-message">
            <div
              className="matadot-live-chat-123789-phone"
              style={{ backgroundColor }}
            >
              {organization_name}
            </div>
            <h2 className="matadot-live-chat-123789-h2">
              {strings.WE_GOT_YOUR_MESSAGE}!
            </h2>
            <div className="matadot-live-chat-123789-text">
              {second_message}
            </div>
            <div
              className="matadot-live-chat-123789-check-wrapper"
              style={{ backgroundColor: color }}
            >
              {Iconset.checkIcon}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SuccessMessage;
