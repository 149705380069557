import React, { useState } from "react";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ReactComponent as CalendarIcon } from "./assets/calendar.svg";
import { strings } from "../../../utils/localization";
import moment from "moment-timezone";

const AppointmentBooking = ({
  color,
  organizationTimeZone,
  organizationWorkingHours,
  organizationCustomWorkingHours = [],
  hideAppointmentBooking,
  submitBooking,
}) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState("");
  const [availableTimes, setAvailableTimes] = useState([]);

  const isDateOpen = (date) => {
    const dateString = moment(date).format("YYYY-MM-DD");

    const customHours = organizationCustomWorkingHours.find(
      (customDate) => customDate.date === dateString
    );

    if (customHours) {
      return customHours.isOpen;
    }

    const dayName = moment(date).tz(organizationTimeZone).format("dddd");
    const dayWorkingHours = organizationWorkingHours[dayName];

    return dayWorkingHours?.isOpen || false;
  };

  const generateAvailableTimes = (date) => {
    const dateString = moment(date).format("YYYY-MM-DD");
    let workingHours = null;

    const customHours = organizationCustomWorkingHours.find(
      (customDate) => customDate.date === dateString
    );

    if (customHours && customHours.isOpen) {
      workingHours = customHours;
    } else {
      const dayName = moment(date).tz(organizationTimeZone).format("dddd");
      const dayWorkingHours = organizationWorkingHours[dayName];

      if (dayWorkingHours && dayWorkingHours.isOpen) {
        workingHours = dayWorkingHours;
      }
    }

    if (!workingHours) {
      return [];
    }

    const times = [];
    const startTime = moment
      .tz(
        `${dateString} ${workingHours.from}`,
        "YYYY-MM-DD h:mm A",
        organizationTimeZone
      )
      .startOf("minute");

    const endTime = moment
      .tz(
        `${dateString} ${workingHours.to}`,
        "YYYY-MM-DD h:mm A",
        organizationTimeZone
      )
      .startOf("minute");

    const nowInOrgTz = moment.tz(organizationTimeZone).startOf("minute");

    if (startTime.minutes() % 30 !== 0) {
      startTime.minutes(Math.ceil(startTime.minutes() / 30) * 30).seconds(0);
    }

    const currentTime = startTime.clone();

    while (currentTime.isBefore(endTime)) {
      if (moment(date).isSame(nowInOrgTz, "day")) {
        if (currentTime.isSameOrAfter(nowInOrgTz)) {
          times.push(currentTime.format("hh:mm A"));
        }
      } else {
        times.push(currentTime.format("hh:mm A"));
      }
      currentTime.add(30, "minutes");
    }

    return times;
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setSelectedTime("");
    const times = generateAvailableTimes(date);
    setAvailableTimes(times);
  };

  const handleTimeChange = (event) => {
    setSelectedTime(event.target.value);
  };

  const handleSubmit = () => {
    submitBooking({
      date: selectedDate,
      time: selectedTime,
    });
  };

  const currentDay = moment().tz(organizationTimeZone).toDate();

  return (
    <BookingContainer>
      <FormField>
        <Title>{strings.APPOINTMENT_BOOKING}</Title>
        <Label htmlFor="date-picker">{strings.DATE}</Label>
        <StyledDatePicker
          id="date-picker"
          selected={selectedDate}
          onChange={handleDateChange}
          dateFormat="MMMM d, yyyy"
          placeholderText={strings.CHOOSE_DATE}
          filterDate={isDateOpen}
          minDate={currentDay}
        />
      </FormField>

      <FormField>
        <Label htmlFor="time-select">{strings.TIME}</Label>
        <Select
          id="time-select"
          value={selectedTime}
          onChange={handleTimeChange}
          disabled={!selectedDate}
          aria-label={strings.TIME}
        >
          <option value="">{strings.CHOOSE_TIME}</option>
          {availableTimes.map((time) => (
            <option key={time} value={time}>
              {time}
            </option>
          ))}
        </Select>
      </FormField>

      <BookingButton
        onClick={handleSubmit}
        disabled={!selectedDate || !selectedTime}
        color={color}
        type="button"
      >
        {strings.BOOK_APPOINTMENT}
        <CalendarIcon />
      </BookingButton>

      <TimeZoneLabel>
        {strings.APPOINTMENT_TIMEZONE} {organizationTimeZone}
      </TimeZoneLabel>

      <CancelLabel onClick={hideAppointmentBooking}>
        {strings.CANCEL}
      </CancelLabel>
    </BookingContainer>
  );
};

export default AppointmentBooking;

const BookingContainer = styled.div`
  padding: 12px;
  background-color: #f6f7fb;
  border-radius: 16px;
  width: 80%;
  margin-top: 10px;

  .react-datepicker-wrapper {
    width: 100%;
  }
`;

const Title = styled.p`
  color: #0e0e0e;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 12px;
  margin-top: 0;
`;

const FormField = styled.div`
  margin-bottom: 15px;
`;

const Label = styled.label`
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  display: block;
  color: #77808d;
  margin-bottom: 2px;
`;

const Select = styled.select`
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  font-size: 15px;
  border-radius: 8px;
`;

const StyledDatePicker = styled(DatePicker)`
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  font-size: 15px;
  border-radius: 8px;
`;

const BookingButton = styled.button`
  width: 100%;
  color: white;
  font-size: 15px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  background-color: ${(props) => props.color || "#4CAF50"};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-top: 20px;

  height: 36px;
  padding: 10px 12px;
  border-radius: 60px;

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

const TimeZoneLabel = styled.p`
  margin-top: 10px;
  font-size: 12px;
  color: #0e0e0e;
  text-align: center;
`;

const CancelLabel = styled.p`
  margin-top: 10px;
  font-size: 12px;
  color: #77808d;
  text-align: center;
  cursor: pointer;
  color: #77808d;
`;
