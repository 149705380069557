import React from "react";
import { strings } from "../../utils/localization";
import { Iconset } from "../Icons/Icons";
import styled from "styled-components";

const QuestionHeader = styled.p`
  font-size: 18px;
  line-height: 30px;
  color: #fff;
  margin: 0;
  margin-left: 10px;
`;
const ChatHeaderQuestion = () => {
  return (
    <>
      <div
        style={{ display: "flex", alignItems: "center", paddingBottom: "15px" }}
      >
        {Iconset.startConversation}
        <QuestionHeader>
          {strings.TEXT_CHAT_HELP_START_CONVERSATION}
        </QuestionHeader>
      </div>
    </>
  );
};

export default ChatHeaderQuestion;
