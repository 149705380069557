import React from "react";
import styled from "styled-components";
import { strings } from "../../utils/localization";

const FooterText = styled.span`
  font-family: "Roboto";
  letter-spacing: 0px;
  color: ${(props) => props.color || "#A3A6BE"};
  font-size: 9px;
  cursor: pointer;
`;

const Wrapper = styled.div`
  text-align: center;
  padding-top: 8px;
  font-size: 0;
`;

const LightFooter = () => {
  return (
    <Wrapper>
      <FooterText>
        {strings.USE_AS_SUBJECT_TO}
        <a
          target="_blank"
          aria-label="terms and conditions link"
          style={{ textDecoration: "none" }}
          href="http://matador.ai/?utm_source=matador%20connect%20widget&utm_medium=link&utm_campaign=widget%20referral"
        >
          <FooterText color="#706AFF"> {strings.TERMS}</FooterText>
        </a>
        . {strings.TEXT_MESSAGE_FEES_MAY_APPLY}
      </FooterText>
    </Wrapper>
  );
};

export default LightFooter;
