import React from "react";
import styled from "styled-components";
import { strings } from "../../utils/localization";

import { ReactComponent as MessageIcon } from "./assets/smsIcon.svg";

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    fill: ${(props) => `${props.fillcolor} !important` || "#fff"};
  }
`;

const Text = styled.p`
  margin: 0px;
  color: ${(props) => props.textColor};
  font-size: ${(props) => `${props.fontSize}px`};
  margin-left: 5px;
  font-weight: ${(props) => (props.isBold ? "bold" : "normal")};
`;

const Icon = styled(MessageIcon)`
  height: 18px;
  width: 23px;
  margin-right: 3px;
`;

const CtaThankYou = ({
  textColor = "#FFFFFF",
  onClick,
  fontSize,
  isInitialTextMessageBold,
}) => {
  return (
    <Container onClick={onClick} fillcolor={textColor}>
      <Icon alt={strings.CTA_THANK_YOU} />
      <Text
        fontSize={fontSize}
        textColor={textColor}
        isBold={isInitialTextMessageBold}
      >
        {strings.CTA_THANK_YOU}
      </Text>
    </Container>
  );
};

export default CtaThankYou;
