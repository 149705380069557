import { useEffect, useRef } from "react";

const AdjustFontSize = ({ className }) => {
  const resizeObserverRef = useRef(null);
  const mutationObserverRef = useRef(null);

  const adjustFontSize = () => {
    const elements = document.querySelectorAll(className);

    elements.forEach((element) => {
      const parentWidth = element.clientWidth;
      const textWidth = element.scrollWidth;

      const fontSize = Math.floor(
        (parentWidth / textWidth) *
          parseInt(window.getComputedStyle(element).fontSize)
      );

      // Set a minimum and maximum font size to avoid extreme values
      const minFontSize = 12;
      const maxFontSize = 36;
      element.style.fontSize = `${Math.min(
        maxFontSize,
        Math.max(minFontSize, fontSize)
      )}px`;
    });
  };

  const observeElements = () => {
    const elements = document.querySelectorAll(className);

    elements.forEach((element) => {
      resizeObserverRef.current.observe(element);
    });
  };

  useEffect(() => {
    adjustFontSize(); // Call it once on component mount

    // Create a ResizeObserver and attach the observe callback to it
    resizeObserverRef.current = new ResizeObserver(adjustFontSize);

    // Create a MutationObserver to watch for DOM changes and add elements to ResizeObserver
    mutationObserverRef.current = new MutationObserver(() => {
      observeElements();
    });

    // Observe changes in the entire document for dynamic-font-size elements
    mutationObserverRef.current.observe(document, {
      childList: true,
      subtree: true,
    });

    return () => {
      // Disconnect the ResizeObserver and MutationObserver on component unmount
      if (resizeObserverRef.current) {
        resizeObserverRef.current.disconnect();
      }
      if (mutationObserverRef.current) {
        mutationObserverRef.current.disconnect();
      }
    };
  }, []);
};

export default AdjustFontSize;
