import React, { useEffect, useState } from "react";
import styled from "styled-components";
import chatSettingsMainActions from "../../../components/modules/chatSettingsMainActions";
import QuestionsList from "../Chat/Questions/QuestionsList";
import { default as ArrowRight } from "./assets/arrow-right.svg";

const Container = styled.div`
  background-color: #fff;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  padding: 16px;
`;

const Item = styled.div`
  border: 1px solid #cdd9ea;
  border-radius: 12px;
  padding: 10px;
  margin-bottom: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    background: #eff1f4;
  }
`;

const Title = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  color: #222222;
  margin: 0px;
`;

const Description = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: #77808d;
  margin: 0px;
  margin-top: 4px;
`;

const Icon = styled.img`
  max-height: 20px;
  cursor: pointer;
  margin-right: 0px;
`;

const LocationWrapper = styled.div`
  height: 227px;
  margin-top: -22px;
  background: #fff;
  margin-bottom: 10px;
`;

const CustomStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  height: 100%;
  flex-direction: column;

  & > div {
    width: 100%;

    & > div {
      width: 100%;
    }

    .question-item-text-display {
      font-size: ${(props) => props.fontSize}px !important;
      text-align: center;
    }
  }
`;

const DATA = [
  {
    mainToken: "1f2557117fa9fe288df82ba488c3a20987c96eda",
    showMap: true,
    addresses: [
      "19400 NW 2nd Ave, Miami, FL 33169, United States",
      "19825 S. Dixie Highway Cutler Bay, FL 33157",
    ],
    organizations: [
      {
        id: 1,
        title: "Peterson's Harley-Davidson Miami",
        address: "19400 NW 2nd Ave Miami, FL 33169",
        questions: [
          [
            {
              text: "Sales",
              token: "1f2557117fa9fe288df82ba488c3a20987c96eda",
            },
            {
              text: "Service",
              token: "16c2533f7c36861a4dd8cb2c707a77d978e0bbd9",
            },
            {
              text: "Parts",
              token: "f4880ff02d34b0865d1186a0706f6961be8f51c2",
            },
            {
              text: "Motorcycle Gear",
              token: "848dbb0b72d26571280eed3acf798ea23f10817f",
            },
            {
              text: "Learn to ride",
              token: "848dbb0b72d26571280eed3acf798ea23f10817f",
            },
            {
              text: "Events / Promotions",
              token: "848dbb0b72d26571280eed3acf798ea23f10817f",
            },
          ],
        ],
        locationIds: [
          "f4880ff02d34b0865d1186a0706f6961be8f51c2",
          "848dbb0b72d26571280eed3acf798ea23f10817f",
          "16c2533f7c36861a4dd8cb2c707a77d978e0bbd9",
        ],
      },
      {
        id: 2,
        title: "Peterson's H-D South",
        address: "19825 S Dixie Hwy Miami, FL 33157",
        questions: [
          {
            text: "Sales",
            token: "e9471cbd2bacb8d167637b26f051f86eaad3c1a2",
          },
          {
            text: "Service",
            token: "37f3b88dec11ceb78e6b609ca89145101f29f90e",
          },
          {
            text: "Parts",
            token: "d9684525d995bbac156533dfea77fb68c36c42ef",
          },
          {
            text: "Motorcycle Gear",
            token: "46cc36df988b3897b229126f5a851a66f090a861",
          },
          {
            text: "Learn to ride",
            token: "46cc36df988b3897b229126f5a851a66f090a861",
          },
          {
            text: "Events / Promotions",
            token: "46cc36df988b3897b229126f5a851a66f090a861",
          },
        ],
        locationIds: [
          "e9471cbd2bacb8d167637b26f051f86eaad3c1a2",
          "37f3b88dec11ceb78e6b609ca89145101f29f90e",
          "d9684525d995bbac156533dfea77fb68c36c42ef",
          "46cc36df988b3897b229126f5a851a66f090a861",
        ],
      },
    ],
    locations: [
      {
        name: "Miami",
        ids: [
          "f4880ff02d34b0865d1186a0706f6961be8f51c2",
          "848dbb0b72d26571280eed3acf798ea23f10817f",
          "16c2533f7c36861a4dd8cb2c707a77d978e0bbd9",
        ],
      },
      {
        name: "South",
        ids: [
          "e9471cbd2bacb8d167637b26f051f86eaad3c1a2",
          "37f3b88dec11ceb78e6b609ca89145101f29f90e",
          "d9684525d995bbac156533dfea77fb68c36c42ef",
          "46cc36df988b3897b229126f5a851a66f090a861",
        ],
      },
    ],
  },
  {
    mainToken: "4a5acc388ff5a369885d3510d983cfc688bb9a09",
    customStyle: true,
    showMap: false,
    fontSize: 20,
    addresses: ["Midway Wyoming", "Midway Montana"],
    organizations: [
      {
        id: 1,
        title: "Midway Montana",
        address: "",
        questions: [
          [
            {
              text: "🚗 Sales ",
              token: "4a5acc388ff5a369885d3510d983cfc688bb9a09",
            },
            {
              text: " 🔧Service",
              token: "f8e4b291876f9158c5b4e64a976f29d184ad966d",
            },
          ],
        ],
        locationIds: [
          "2e408d5921e38bb50df58f8b3c5b02d0e3efdb46",
          "4a5acc388ff5a369885d3510d983cfc688bb9a09",
          "f8e4b291876f9158c5b4e64a976f29d184ad966d",
          "f39a64b965d1f5388dae17472ff412a300e02ac1",
        ],
      },
      {
        id: 2,
        title: "Midway Wyoming",
        address: "",
        questions: [
          {
            text: "🚗 Sales ",
            token: "2e408d5921e38bb50df58f8b3c5b02d0e3efdb46",
          },
          {
            text: " 🔧Service",
            token: "f39a64b965d1f5388dae17472ff412a300e02ac1",
          },
        ],
        locationIds: [
          "4a5acc388ff5a369885d3510d983cfc688bb9a09",
          "2e408d5921e38bb50df58f8b3c5b02d0e3efdb46",
          "f39a64b965d1f5388dae17472ff412a300e02ac1",
          "f8e4b291876f9158c5b4e64a976f29d184ad966d",
        ],
      },
    ],
    locations: [
      {
        name: "Midway Wyoming",
        ids: ["2e408d5921e38bb50df58f8b3c5b02d0e3efdb46"],
      },
      {
        name: "Midway Montana",
        ids: ["4a5acc388ff5a369885d3510d983cfc688bb9a09"],
      },
      {
        name: "Midway Montana",
        ids: ["f8e4b291876f9158c5b4e64a976f29d184ad966d"],
      },
      {
        name: "Midway Wyoming",
        ids: ["f39a64b965d1f5388dae17472ff412a300e02ac1"],
      },
    ],
  },
  {
    mainToken: "b8652cd2677fead95e8594c2ad2e0b2026405b0f",
    customStyle: true,
    showMap: false,
    addresses: [
      "Bedard Honda",
      "Bedard CDJR",
      "Bedard Volvo",
      "Bedard Bros Chevrolet",
    ],
    organizations: [
      {
        id: 1,
        title: "Bedard Honda",
        address: "",
        questions: [
          [
            {
              text: "🚗 Sales ",
              token: "b8652cd2677fead95e8594c2ad2e0b2026405b0f",
            },
            {
              text: " 🔧Service",
              token: "b8652cd2677fead95e8594c2ad2e0b2026405b0f",
            },
          ],
        ],
        locationIds: ["b8652cd2677fead95e8594c2ad2e0b2026405b0f"],
      },
      {
        id: 2,
        title: "Bedard CDJR",
        address: "",
        questions: [
          {
            text: "🚗 Sales ",
            token: "b8652cd2677fead95e8594c2ad2e0b2026405b0f",
          },
          {
            text: " 🔧Service",
            token: "b8652cd2677fead95e8594c2ad2e0b2026405b0f",
          },
        ],
        locationIds: ["b8652cd2677fead95e8594c2ad2e0b2026405b0f"],
      },
      {
        id: 3,
        title: "Bedard Volvo",
        address: "",
        questions: [
          {
            text: "🚗 Sales ",
            token: "b8652cd2677fead95e8594c2ad2e0b2026405b0f",
          },
          {
            text: " 🔧Service",
            token: "b8652cd2677fead95e8594c2ad2e0b2026405b0f",
          },
        ],
        locationIds: ["b8652cd2677fead95e8594c2ad2e0b2026405b0f"],
      },
      {
        id: 4,
        title: "Bedard Bros Chevrolet",
        address: "",
        questions: [
          {
            text: "🚗 Sales ",
            token: "bab7513674b3330730a40f2088d26235259dc0c9",
          },
          {
            text: " 🔧Service",
            token: "bab7513674b3330730a40f2088d26235259dc0c9",
          },
        ],
        locationIds: ["bab7513674b3330730a40f2088d26235259dc0c9"],
      },
    ],
    locations: [
      {
        name: "Bedard Honda",
        ids: ["b8652cd2677fead95e8594c2ad2e0b2026405b0f"],
      },
      {
        name: "Bedard CDJR",
        ids: ["b8652cd2677fead95e8594c2ad2e0b2026405b0f"],
      },
      {
        name: "Bedard Volvo",
        ids: ["b8652cd2677fead95e8594c2ad2e0b2026405b0f"],
      },
      {
        name: "Bedard Bros Chevrolet",
        ids: ["bab7513674b3330730a40f2088d26235259dc0c9"],
      },
    ],
  },
  {
    mainToken: "694d3d0023e6693c4d95916b3172181bc37e0e6c",
    customStyle: false,
    showMap: false,
    addresses: ["Auto Boutique Florida"],
    organizations: [
      {
        id: 1,
        title: "Auto Boutique Florida",
        useMatadorConnectForOtherQuestions: true,
        address: "",
        questions: [
          [
            {
              text: "🚗 Book test drive.",
              token: "694d3d0023e6693c4d95916b3172181bc37e0e6c",
              description:
                "We would love to book a test drive for you.  What vehicle are you interested in and when will you be available to come in the store.",
            },
            {
              text: "💬 Value my trade.",
              token: "694d3d0023e6693c4d95916b3172181bc37e0e6c",
              description:
                "We have received your request to value your trade, please provide us with your year, make and model of the trade along with any other details you would like to share.",
            },
          ],
        ],
        locationIds: ["694d3d0023e6693c4d95916b3172181bc37e0e6c"],
      },
    ],
    locations: [
      {
        name: "Auto Boutique Florida",
        ids: ["694d3d0023e6693c4d95916b3172181bc37e0e6c"],
      },
    ],
  },
  {
    mainToken: "e46361ef0f2105b8e37870e67d48af737872536c",
    customStyle: true,
    showMap: false,
    locationFirst: true,
    addresses: [],
    organizations: [
      {
        id: 1,
        title: "Sales",
        address: "",
        token: "e46361ef0f2105b8e37870e67d48af737872536c",
        questions: [
          [
            {
              text: "🚗 Book a Test Drive",
              token: "e46361ef0f2105b8e37870e67d48af737872536c",
            },
            {
              text: "🤝 Value My Trade",
              token: "e46361ef0f2105b8e37870e67d48af737872536c",
            },
            {
              text: "💬 Get Pre-qualified",
              token: "e46361ef0f2105b8e37870e67d48af737872536c",
            },
            {
              text: "🦊 Send Me The Carfax",
              token: "e46361ef0f2105b8e37870e67d48af737872536c",
            },
          ],
        ],
        locationIds: ["e46361ef0f2105b8e37870e67d48af737872536c"],
      },
      {
        id: 2,
        title: "Service",
        address: "",
        token: "1f44cba56b7a55c9e5272d152d3aedb624dbccfa",
        questions: [
          {
            text: "🔧 Book an Oil Change",
            token: "1f44cba56b7a55c9e5272d152d3aedb624dbccfa",
          },
          {
            text: "🛞 Get Tires Swapped",
            token: "1f44cba56b7a55c9e5272d152d3aedb624dbccfa",
          },
          {
            text: "📞 Request a Call Back",
            token: "1f44cba56b7a55c9e5272d152d3aedb624dbccfa",
          },
          {
            text: "🛠 Other Servicing",
            token: "1f44cba56b7a55c9e5272d152d3aedb624dbccfa",
          },
        ],
        locationIds: ["1f44cba56b7a55c9e5272d152d3aedb624dbccfa"],
      },
    ],
    locations: [
      {
        name: "Sales",
        ids: ["e46361ef0f2105b8e37870e67d48af737872536c"],
      },
      {
        name: "Service",
        ids: ["1f44cba56b7a55c9e5272d152d3aedb624dbccfa"],
      },
    ],
  },

  // new
  {
    mainToken: "840767fd797b8accf99e4a6bb0186cb8459f6313",
    customStyle: true,
    showMap: false,
    fontSize: 20,
    addresses: ["Clawson Honda Of Fresno"],
    organizations: [
      {
        id: 1,
        title: "Clawson Honda Of Fresno",
        token: "840767fd797b8accf99e4a6bb0186cb8459f6313",
        address: "",
        questions: [
          [
            {
              text: "Sales",
              token: "840767fd797b8accf99e4a6bb0186cb8459f6313",
            },
            {
              text: "Service",
              token: "b8a6d2d2381abdb10b00e2909682e7d0797fd384",
            },
            {
              text: "Parts",
              token: "65d1e737558bcda3a4324e9a240b39984d8c477f",
            },
          ],
        ],
        locationIds: [
          "840767fd797b8accf99e4a6bb0186cb8459f6313",
          "b8a6d2d2381abdb10b00e2909682e7d0797fd384",
          "65d1e737558bcda3a4324e9a240b39984d8c477f",
        ],
      },
    ],
    locations: [
      {
        name: "Clawson Honda Of Fresno",
        ids: ["840767fd797b8accf99e4a6bb0186cb8459f6313"],
      },
      {
        name: "Clawson Honda of Fresno Service",
        ids: ["b8a6d2d2381abdb10b00e2909682e7d0797fd384"],
      },
      {
        name: "Clawson Honda of Fresno Parts",
        ids: ["65d1e737558bcda3a4324e9a240b39984d8c477f"],
      },
    ],
  },
  {
    mainToken: "22e9834861b07674eee40d2a1178a64c05aba6af",
    showMap: false,
    customStyle: true,
    addresses: [
      "Axio Auto Ogden",
      "Axio Auto Boise",
      "Axio Auto Orem",
      "Axio Auto Sandy",
    ],
    organizations: [
      {
        id: 1,
        title: "Axio Auto Ogden",
        address: "",
        questions: [
          {
            text: "🚗 Book test drive.",
            token: "22e9834861b07674eee40d2a1178a64c05aba6af",
          },
          {
            text: "🤝 Value my trade.",
            token: "22e9834861b07674eee40d2a1178a64c05aba6af",
          },
          {
            text: "💸 Apply for financing.",
            token: "22e9834861b07674eee40d2a1178a64c05aba6af",
          },
          {
            text: "💬 Get pre-qualified.",
            token: "22e9834861b07674eee40d2a1178a64c05aba6af",
          },
          {
            text: "👍 Ask anything",
            token: "22e9834861b07674eee40d2a1178a64c05aba6af",
          },
        ],
        locationIds: ["22e9834861b07674eee40d2a1178a64c05aba6af"],
      },
      {
        id: 2,
        title: "Axio Auto Boise",
        address: "",
        questions: [
          {
            text: "🚗 Book test drive.",
            token: "c1c2205f3dc335ae0caf112f8cc8c382e779cb55",
          },
          {
            text: "🤝 Value my trade.",
            token: "c1c2205f3dc335ae0caf112f8cc8c382e779cb55",
          },
          {
            text: "💸 Apply for financing.",
            token: "c1c2205f3dc335ae0caf112f8cc8c382e779cb55",
          },
          {
            text: "💬 Get pre-qualified.",
            token: "c1c2205f3dc335ae0caf112f8cc8c382e779cb55",
          },
          {
            text: "💬 Get pre-qualified.",
            token: "c1c2205f3dc335ae0caf112f8cc8c382e779cb55",
          },
          {
            text: "👍 Ask anything",
            token: "c1c2205f3dc335ae0caf112f8cc8c382e779cb55",
          },
        ],
        locationIds: ["c1c2205f3dc335ae0caf112f8cc8c382e779cb55"],
      },
      {
        id: 3,
        title: "Axio Auto Orem",
        address: "",
        questions: [
          {
            text: "🚗 Book test drive.",
            token: "bf0743f0aabad6a40af72629577a271f6ad831a9",
          },
          {
            text: "🤝 Value my trade.",
            token: "bf0743f0aabad6a40af72629577a271f6ad831a9",
          },
          {
            text: "💸 Apply for financing.",
            token: "bf0743f0aabad6a40af72629577a271f6ad831a9",
          },
          {
            text: "💬 Get pre-qualified.",
            token: "bf0743f0aabad6a40af72629577a271f6ad831a9",
          },
          {
            text: "👍 Ask anything",
            token: "bf0743f0aabad6a40af72629577a271f6ad831a9",
          },
        ],
        locationIds: ["bf0743f0aabad6a40af72629577a271f6ad831a9"],
      },
      {
        id: 4,
        title: "Axio Auto Sandy",
        address: "",
        questions: [
          {
            text: "🚗 Book test drive.",
            token: "b99568dd413c67832b25131e308189caa59c5b08",
          },
          {
            text: "🤝 Value my trade.",
            token: "b99568dd413c67832b25131e308189caa59c5b08",
          },
          {
            text: "💸 Apply for financing.",
            token: "b99568dd413c67832b25131e308189caa59c5b08",
          },
          {
            text: "💬 Get pre-qualified.",
            token: "b99568dd413c67832b25131e308189caa59c5b08",
          },
          {
            text: "👍 Ask anything",
            token: "b99568dd413c67832b25131e308189caa59c5b08",
          },
        ],
        locationIds: ["b99568dd413c67832b25131e308189caa59c5b08"],
      },
    ],
    locations: [
      {
        name: "Axio Auto Ogden",
        ids: ["22e9834861b07674eee40d2a1178a64c05aba6af"],
      },
      {
        name: "Axio Auto Boise",
        ids: ["c1c2205f3dc335ae0caf112f8cc8c382e779cb55"],
      },
      {
        name: "Axio Auto Orem",
        ids: ["bf0743f0aabad6a40af72629577a271f6ad831a9"],
      },
      {
        name: "Axio Auto Sandy",
        ids: ["b99568dd413c67832b25131e308189caa59c5b08"],
      },
    ],
  },
  {
    mainToken: "ded38f25c8e887e86514b7f28a6078c17d7a7ea1",
    showMap: false,
    customStyle: true,
    addresses: [
      "Haddad Hyundai",
      "Haddad Subaru",
      "Haddad Subaru of St. Albans",
      "Haddad Toyota",
    ],
    organizations: [
      {
        id: 1,
        title: "Haddad Hyundai",
        address: "",
        questions: [
          {
            text: "🚗 Sales",
            token: "46fd9c4527a23e01c5df284e82ff7e91946b01e7",
          },
          {
            text: "🔧 Service",
            token: "028328922564f8106c10d31761d503b36f983381",
          },
        ],
        locationIds: [
          "46fd9c4527a23e01c5df284e82ff7e91946b01e7",
          "028328922564f8106c10d31761d503b36f983381",
        ],
      },
      {
        id: 2,
        title: "Haddad Subaru",
        address: "",
        questions: [
          {
            text: "🚗 Sales",
            token: "6c61d2b45b1c72dcab5cf0c518bee6fabd2cc049",
          },
          {
            text: "🔧 Service",
            token: "a3c1faeea7a963e642995963ca87137198cd9215",
          },
        ],
        locationIds: [
          "6c61d2b45b1c72dcab5cf0c518bee6fabd2cc049",
          "a3c1faeea7a963e642995963ca87137198cd9215",
        ],
      },
      {
        id: 3,
        title: "Haddad Subaru of St. Albans",
        address: "",
        questions: [
          {
            text: "🚗 Sales",
            token: "7074fafbed619be5399d6ef37d10514d03f4fe0d",
          },
          {
            text: "🔧 Service",
            token: "71888f9b993136ea93bb7d281bdec302bb2de023",
          },
        ],
        locationIds: [
          "7074fafbed619be5399d6ef37d10514d03f4fe0d",
          "71888f9b993136ea93bb7d281bdec302bb2de023",
        ],
      },
      {
        id: 4,
        title: "Haddad Toyota",
        address: "",
        questions: [
          {
            text: "🚗 Sales",
            token: "f87118098be828b28084e6ab206444a8a05c50e1",
          },
          {
            text: "🔧 Service",
            token: "7ec4ef6e7ff0fda0eee925574d747cd943abf83d",
          },
        ],
        locationIds: [
          "f87118098be828b28084e6ab206444a8a05c50e1",
          "7ec4ef6e7ff0fda0eee925574d747cd943abf83d",
        ],
      },
    ],
    locations: [
      {
        name: "Haddad Hyundai",
        ids: ["46fd9c4527a23e01c5df284e82ff7e91946b01e7"],
      },
      {
        name: "Haddad Subaru",
        ids: ["6c61d2b45b1c72dcab5cf0c518bee6fabd2cc049"],
      },
      {
        name: "Haddad Subaru of St. Albans",
        ids: ["7074fafbed619be5399d6ef37d10514d03f4fe0d"],
      },
      {
        name: "Haddad Toyota",
        ids: ["f87118098be828b28084e6ab206444a8a05c50e1"],
      },
      {
        name: "Haddad Hyundai",
        ids: ["028328922564f8106c10d31761d503b36f983381"],
      },
      {
        name: "Haddad Subaru",
        ids: ["a3c1faeea7a963e642995963ca87137198cd9215"],
      },
      {
        name: "Haddad Subaru of St. Albans",
        ids: ["71888f9b993136ea93bb7d281bdec302bb2de023"],
      },
      {
        name: "Haddad Toyota",
        ids: ["7ec4ef6e7ff0fda0eee925574d747cd943abf83d"],
      },
    ],
  },
  {
    mainToken: "46fd9c4527a23e01c5df284e82ff7e91946b01e7",
    showMap: false,
    customStyle: true,
    addresses: ["Haddad Hyundai"],
    organizations: [
      {
        id: 1,
        title: "Haddad Hyundai",
        address: "",
        questions: [
          {
            text: "🚗 Sales",
            token: "46fd9c4527a23e01c5df284e82ff7e91946b01e7",
          },
          {
            text: "🔧 Service",
            token: "028328922564f8106c10d31761d503b36f983381",
          },
        ],
        locationIds: [
          "46fd9c4527a23e01c5df284e82ff7e91946b01e7",
          "028328922564f8106c10d31761d503b36f983381",
        ],
      },
    ],
    locations: [
      {
        name: "Haddad Hyundai",
        ids: [
          "46fd9c4527a23e01c5df284e82ff7e91946b01e7",
          "028328922564f8106c10d31761d503b36f983381",
        ],
      },
    ],
  },
  {
    mainToken: "6c61d2b45b1c72dcab5cf0c518bee6fabd2cc049",
    showMap: false,
    customStyle: true,
    addresses: ["Haddad Subaru"],
    organizations: [
      {
        id: 1,
        title: "Haddad Subaru",
        address: "",
        questions: [
          {
            text: "🚗 Sales",
            token: "6c61d2b45b1c72dcab5cf0c518bee6fabd2cc049",
          },
          {
            text: "🔧 Service",
            token: "a3c1faeea7a963e642995963ca87137198cd9215",
          },
        ],
        locationIds: [
          "6c61d2b45b1c72dcab5cf0c518bee6fabd2cc049",
          "a3c1faeea7a963e642995963ca87137198cd9215",
        ],
      },
    ],
    locations: [
      {
        name: "Haddad Subaru",
        ids: [
          "6c61d2b45b1c72dcab5cf0c518bee6fabd2cc049",
          "a3c1faeea7a963e642995963ca87137198cd9215",
        ],
      },
    ],
  },
  {
    mainToken: "7074fafbed619be5399d6ef37d10514d03f4fe0d",
    showMap: false,
    customStyle: true,
    addresses: ["Haddad Subaru of St. Albans"],
    organizations: [
      {
        id: 1,
        title: "Haddad Subaru of St. Albans",
        address: "",
        questions: [
          {
            text: "🚗 Sales",
            token: "7074fafbed619be5399d6ef37d10514d03f4fe0d",
          },
          {
            text: "🔧 Service",
            token: "71888f9b993136ea93bb7d281bdec302bb2de023",
          },
        ],
        locationIds: [
          "7074fafbed619be5399d6ef37d10514d03f4fe0d",
          "71888f9b993136ea93bb7d281bdec302bb2de023",
        ],
      },
    ],
    locations: [
      {
        name: "Haddad Subaru of St. Albans",
        ids: [
          "7074fafbed619be5399d6ef37d10514d03f4fe0d",
          "71888f9b993136ea93bb7d281bdec302bb2de023",
        ],
      },
    ],
  },
  {
    mainToken: "f87118098be828b28084e6ab206444a8a05c50e1",
    showMap: false,
    customStyle: true,
    addresses: ["Haddad Toyota"],
    organizations: [
      {
        id: 1,
        title: "Haddad Toyota",
        address: "",
        questions: [
          {
            text: "🚗 Sales",
            token: "f87118098be828b28084e6ab206444a8a05c50e1",
          },
          {
            text: "🔧 Service",
            token: "7ec4ef6e7ff0fda0eee925574d747cd943abf83d",
          },
        ],
        locationIds: [
          "f87118098be828b28084e6ab206444a8a05c50e1",
          "7ec4ef6e7ff0fda0eee925574d747cd943abf83d",
        ],
      },
    ],
    locations: [
      {
        name: "Haddad Toyota",
        ids: [
          "f87118098be828b28084e6ab206444a8a05c50e1",
          "7ec4ef6e7ff0fda0eee925574d747cd943abf83d",
        ],
      },
    ],
  },
  {
    mainToken: "1616682101320b358d300f486f45df99f23cd7f6",
    showMap: false,
    addresses: [],
    organizations: [
      {
        id: 1,
        title: "Nissan Chomedey (Vente)",
        address: "",
        token: "1616682101320b358d300f486f45df99f23cd7f6",
        questions: [
          [
            {
              text: "Planifiez un essai routier",
              token: "1616682101320b358d300f486f45df99f23cd7f6",
            },
            {
              text: "Prendre un rendez-vous de service",
              token: "5f01154fa6e195890c6bedde8ecaf3f661bbe744",
            },
            {
              text: "Je veux acheter un véhicule",
              token: "1616682101320b358d300f486f45df99f23cd7f6",
            },
            {
              text: "J'aimerais obtenir une pré-approbation",
              token: "1616682101320b358d300f486f45df99f23cd7f6",
            },
            {
              text: "Combien vaut mon véhicule actuel?",
              token: "1616682101320b358d300f486f45df99f23cd7f6",
            },
          ],
        ],
        locationIds: [
          "1616682101320b358d300f486f45df99f23cd7f6",
          "5f01154fa6e195890c6bedde8ecaf3f661bbe744",
        ],
      },
    ],
    locations: [
      {
        name: "Nissan Chomedey (Vente)",
        ids: ["1616682101320b358d300f486f45df99f23cd7f6"],
      },
      {
        name: "Nissan Chomedey Service",
        ids: ["5f01154fa6e195890c6bedde8ecaf3f661bbe744"],
      },
    ],
  },
];

const SelectOrganization = ({
  listQuestions,
  chatData,
  triggers,
  handleSelectQuestion,
  setQuestionId,
  setSelectedOrganization,
  setClientUIXToken,
  questionPicked,
  setQuestionPicked,
  setLoading,
  setUixFormDescription,
  setIsClientUIX,
  onQuestionClick,
  setIsCustomSubmitForm,
  selectedOrganization,
  setTriggers,
  setShowBackIcon,
  parentURL,
}) => {
  const [locations, setLocations] = useState([]);
  const [showMap, setShowMap] = useState();
  const [customContainerStyle, setCustomContainerStyle] = useState();

  useEffect(() => {
    const data = DATA.find((x) => x.mainToken === chatData?._token);
    setShowMap(data?.showMap);
    if (data.customStyle) {
      setCustomContainerStyle(<CustomStyle fontSize={data.fontSize || 14} />);
    }
    if (data) {
      const { locations } = data;
      const locationIds = locations.flatMap((location) => location.ids);
      setLoading(true);
      getLocationData(locationIds);
    }
  }, [chatData]);

  useEffect(() => {
    if (questionPicked) {
      moveToNextIfOneLocation();
    }
  }, [questionPicked]);

  const getLocationData = async (locationIds) => {
    const locationData = await Promise.all(
      locationIds.map(async (id) => {
        const result = await chatSettingsMainActions.getSingleForWidget(
          { language: "en" },
          id
        );
        return result.data;
      })
    );
    setLocations([{ chatData, triggers }, ...locationData]);
    setLoading(false);
  };

  const getAddresses = () => {
    const data = DATA.find((x) => x.mainToken === chatData?._token);
    if (data) {
      return data.addresses;
    } else {
      return [];
    }
  };

  const findQuestionId = ({ questions, selectedTextQuestion }) => {
    let selectedQuestion = null;

    const getText = ({ item }) => {
      return item._question.question["en"];
    };

    questions.forEach((item) => {
      if (
        getText({ item })?.replace(/\s/g, "")?.trim() === selectedTextQuestion
      ) {
        selectedQuestion = item;
      }
    });

    if (selectedQuestion) {
      setQuestionId(selectedQuestion._question._id);
      let answerText = "";
      if (selectedQuestion._question?.answer?.answer["en"]) {
        answerText = selectedQuestion._question.answer.answer["en"];
      }
      handleSelectQuestion(
        selectedQuestion._question.question["en"],
        selectedQuestion._question.type,
        answerText
      );
    } else {
      console.log("Question can't be found");
    }
  };

  const getOrganizations = () => {
    const data = DATA.find((x) => x.mainToken === chatData?._token);
    if (data) {
      return data.organizations;
    } else {
      return [];
    }
  };

  const findLocation = ({ token, selectedTextQuestion, description }) => {
    let selectedLocation = null;
    const data = locations.find((x) => x.chatData._token === token);

    if (data) {
      selectedLocation = data;
    }

    if (selectedLocation) {
      setClientUIXToken(selectedLocation.chatData._token);
      if (selectedTextQuestion) {
        findQuestionId({
          questions: selectedLocation.triggers[0].questions,
          selectedTextQuestion,
        });
      }
      setUixFormDescription(description);
    } else {
      console.log("Location is not found");
    }
  };

  const moveToNextIfOneLocation = () => {
    // handle case when there is only one location
    if (
      chatData._location_id === "641dac730ce3ba0013160afb" ||
      chatData._location_id === "64b5674e3f7d8000130c047d" ||
      chatData._location_id === "64df80884ff9ec0013fb270c" ||
      chatData._location_id === "64df89845146220013298f8e" ||
      chatData._location_id === "64df7f1b4ff9ec0013fb2552" ||
      chatData._location_id === "64df991e4ff9ec0013fb468c" ||
      chatData._location_id === "5eab099742f73e00123db711"
    ) {
      const items = DATA.find((x) => x.mainToken === chatData?._token);

      next(items.organizations[0]);
    }
  };

  const onQuestionHandler = (data) => {
    const organization = DATA.find((x) => x.mainToken === chatData?._token);

    if (organization.locationFirst) {
      next(selectedOrganization, data);
    } else {
      setQuestionPicked(data);
    }
  };

  const handleSelectingDefaultQuestion = (questionPicked) => {
    // Handle case when all other questions will use default matador connect instead of custom
    setIsClientUIX(false);
    onQuestionClick(questionPicked);
    setIsCustomSubmitForm(false);
  };

  const updateTriggers = (token) => {
    if (!token) return null;
    const data = locations.find((x) => x.chatData._token === token);
    setShowBackIcon(true);
    setTriggers({ triggers: data.triggers, parentURL });
  };

  const next = (selectedOrganization, questionData) => {
    const organizations = getOrganizations();
    const data = organizations.find((x) => x.id === selectedOrganization?.id);
    if (!questionPicked) {
      updateTriggers(data?.token);
    }
    setSelectedOrganization(selectedOrganization);
    if (questionPicked || questionData) {
      const { question } = questionPicked || questionData;
      let item = "";

      if (data && data.questions[0]) {
        if (data) {
          const searchTerm = question?.replace(/\s/g, "")?.trim();

          if (question) {
            if (Array.isArray(data?.questions[0])) {
              item = data.questions[0]?.find(
                (x) => x.text?.replace(/\s/g, "") === searchTerm
              );
            } else {
              item = data.questions?.find(
                (x) => x.text?.replace(/\s/g, "") === searchTerm
              );
            }
          } else {
            item = data;
          }
        }

        if (selectedOrganization?.useMatadorConnectForOtherQuestions && !item) {
          handleSelectingDefaultQuestion(questionPicked);
        } else {
          findLocation({
            token: item.token,
            selectedTextQuestion: question?.replace(/\s/g, "")?.trim(),
            description: item?.description,
          });
        }

        if (!item) {
          console.log("Token is not found, please check question");
        }
      }
    }
  };

  const renderQuestions = () => {
    return (
      <QuestionsList
        questions={listQuestions}
        onQuestionClick={onQuestionHandler}
        isBold={chatData.bold}
      />
    );
  };

  const renderOrganizations = () => {
    const data = DATA.find((x) => x.mainToken === chatData?._token);

    return data?.organizations?.map((item) => (
      <Item key={item.id} onClick={() => next(item)}>
        <div>
          <Title>{item.title}</Title>
          {item.address && <Description>{item.address}</Description>}
        </div>
        <Icon src={ArrowRight} alt="Location" />
      </Item>
    ));
  };

  const renderView = () => {
    const data = DATA.find((x) => x.mainToken === chatData?._token);

    if (data.locationFirst && !selectedOrganization) {
      return renderOrganizations();
    }

    if (questionPicked) {
      return (
        <div style={{ paddingTop: 20 }}>
          <>{renderOrganizations()}</>
        </div>
      );
    }
    return renderQuestions();
  };

  const renderMainContent = () => {
    if (customContainerStyle) {
      return React.cloneElement(customContainerStyle, {}, renderView());
    }

    return <Container>{renderView()}</Container>;
  };

  return renderMainContent();
};

export default SelectOrganization;
