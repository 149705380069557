import { fetchFromUrlGETAsync } from './UrlHelper';

const urlPopup = "/popups/location";

const fs = {
    getPopup: async (locationId) => {
        const urlParams = [];
        return await fetchFromUrlGETAsync(urlPopup + "/" + locationId, urlParams);
    },
};

const loc = {};

const popupMainActions = Object.assign(fs, loc);

export default popupMainActions;