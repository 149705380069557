import React from "react";
import styled from "styled-components";

const Container = styled.div`
  cursor: pointer;
  text-align: center;
`;

const Content = styled.div`
  width: 60px;
  height: 60px;
`;

const Label = styled.p`
  font-size: 10px;
  line-height: 15px;
  color: #222222;
  margin-bottom: 0;
  margin-top: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  padding: 0px 5px;
  line-height: 14px;
  font-weight: 400;
`;

const Image = styled.img`
  height: 100%;
  width: 100%;
  border: 1.5px solid;
  border-color: ${(props) => (props.watched ? "#FFCE31" : "#4db042")};
  box-shadow: 0px -6px 20px rgba(37, 120, 63, 0.25);
  border-radius: 8px;
  object-fit: cover;
`;

const Story = ({ watched, thumbnailUrl, onPlay, title, story }) => {
  return (
    <Container className="story_item" onClick={() => onPlay(story)}>
      <Content>
        <Image src={thumbnailUrl} alt={title} watched={watched} />
      </Content>

      <Label>{title}</Label>
    </Container>
  );
};

export default Story;
