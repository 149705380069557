import React, { useEffect } from 'react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import styled from 'styled-components'

import { strings } from '../../utils/localization'
import SubmitText from '../Buttons/SubmitText'

// New component for new type of styling

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  position: relative;

  .form-control {
    height: ${(props) => (props.theme === 'default' ? '54px' : '36px')};
    border-radius: ${(props) => (props.theme === 'default' ? '27px' : '4px')};
  }

  .selected-flag:hover,
  .selected-flag:focus,
  .selected-flag:active {
    border-radius: ${(props) =>
      props.theme === 'default' ? '27px !important' : '4px !important'};
  }

  .submit-opa {
    position: absolute;
    right: 0;
  }
`

const PhoneField = ({
  value,
  onChange,
  theme,
  enableSubmit,
  onSubmit,
  initialCountry,
  getPhoneNumberFromClipboard,
  onKeyDown,
  primaryColor,
}) => {
  const styles = {
    button: {
      backgroundColor: '#FFFFFF',
      borderWidth: 1,
      borderColor: '#EFEFEF',
      borderRadius: 4,
      margin: 6,
    },
    input: {
      borderWidth: 1,
      backgroundColor: '#EFEFEF',
      borderColor: '#EFEFEF',
      borderRadius: 4,
      width: '100%',
      heigth: 36,
    },
    dropdown: {
      bottom: 35,
      borderRadius: 4,
    },
  }

  const defaultStyles = {
    button: {
      backgroundColor: '#FFFFFF',
      borderWidth: 1,
      borderColor: '#EFEFEF',
      textAlign: 'center',
      marginLeft: 14,
      borderRadius: 20,
      height: 26,
      marginTop: 14,
      paddingLeft: 5,
      paddingRight: 5,
    },
    input: {
      borderWidth: 1,
      backgroundColor: '#EFEFEF',
      borderColor: '#EFEFEF',
      borderRadius: 27,
      width: '100%',
      // padding elft 48
      paddingLeft: 75,
    },
    dropdown: {
      bottom: 35,
      borderRadius: 4,
    },
  }

  useEffect(() => {
    document.querySelectorAll('.text-me')[0] &&
      document
        .querySelectorAll('.text-me')[0]
        .addEventListener('paste', getPhoneNumberFromClipboard)
  }, [])

  const currentStyles = theme === 'light' ? styles : defaultStyles
  const button = theme === 'default' && (
    <SubmitText
      id="submit-button"
      backgroundColor={primaryColor}
      onClick={onSubmit}
      enableSubmit={enableSubmit}
      buttonText={strings.CHAT_TEXT_ME}
    />
  )

  return (
    <Wrapper theme={theme}>
      <PhoneInput
        country={initialCountry || 'us'}
        onlyCountries={['us', 'ca', 'au']}
        inputClass="text-me"
        countryCodeEditable
        value={value}
        placeholder={strings.MOBILE_PHONE}
        onChange={onChange}
        inputStyle={currentStyles.input}
        buttonStyle={currentStyles.button}
        dropdownStyle={currentStyles.dropdown}
        disableDropdown
        onKeyDown={onKeyDown}
      />
      {button}
    </Wrapper>
  )
}

export default PhoneField