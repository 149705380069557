const moment = require("moment-timezone");

// will return Monday, Tuesday, Wednesday, etc.
const getDayOfWeek = ({ date }) => {
  if (!date) {
    return;
  }
  return moment(date).format("dddd");
};

// will return the time now in the timezone provided
const dateNowInTimezone = ({ timezone }) => {
  if (!timezone) {
    return;
  }
  return moment().tz(timezone);
};

// returns from and to working hours and if it is open for the given day of the week provided
const getFromAndToWorkingHoursForDayOfWeek = ({
  workingHours,
  dayOfWeekKey,
}) => {
  if (!workingHours || !dayOfWeekKey) {
    return;
  }
  const dayWorkingHours = workingHours[dayOfWeekKey];
  if (!dayWorkingHours) {
    return;
  }
  const fromHours = dayWorkingHours["from"];
  const toHours = dayWorkingHours["to"];
  const isOpenOnThisWeekDay = dayWorkingHours["isOpen"];
  return { fromHours, toHours, isOpenOnThisWeekDay };
};

const getIsStoreOpen = ({
  timezoneDate,
  fromHours,
  toHours,
  isOpenOnThisWeekDay,
  timezone,
}) => {
  if (!timezoneDate || !fromHours || !toHours || !isOpenOnThisWeekDay) {
    return false;
  }
  if (!isOpenOnThisWeekDay) {
    return false;
  }

  const fromTime = moment.tz(fromHours, "h:mm A", timezone);
  const toTime = moment.tz(toHours, "h:mm A", timezone);

  if (fromTime.isSame(toTime)) {
    return true;
  }

  if (toTime.isBefore(fromTime)) {
    const isAfterOpening = timezoneDate.isSameOrAfter(fromTime);
    const isBeforeClosing = timezoneDate.isBefore(toTime.add(1, "day"));
    return isAfterOpening || isBeforeClosing;
  }

  return timezoneDate.isSameOrAfter(fromTime) && timezoneDate.isBefore(toTime);
};

const isStoreOpen = ({
  organizationTimeZone,
  organizationWorkingHours,
  organizationCustomWorkingHours,
}) => {
  const timezoneDate = dateNowInTimezone({ timezone: organizationTimeZone });
  const currentDay = moment(timezoneDate).format("YYYY-MM-DD");

  let workingHoursForToday = {};

  const customHoursForToday = organizationCustomWorkingHours.find(
    (customHour) => customHour.date === currentDay
  );

  if (customHoursForToday) {
    workingHoursForToday = {
      [getDayOfWeek({ date: timezoneDate })]: {
        isOpen: customHoursForToday.isOpen,
        from: customHoursForToday.from,
        to: customHoursForToday.to,
      },
    };
  } else {
    const dayOfWeekKey = getDayOfWeek({ date: timezoneDate });
    workingHoursForToday = {
      [dayOfWeekKey]: organizationWorkingHours[dayOfWeekKey],
    };
  }

  const result = getFromAndToWorkingHoursForDayOfWeek({
    workingHours: workingHoursForToday,
    dayOfWeekKey: getDayOfWeek({ date: timezoneDate }),
  });

  if (!result) {
    return false;
  }

  const { fromHours, toHours, isOpenOnThisWeekDay } = result;
  return getIsStoreOpen({
    timezoneDate,
    fromHours,
    toHours,
    isOpenOnThisWeekDay,
    timezone: organizationTimeZone,
  });
};

export default isStoreOpen;
