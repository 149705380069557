import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import Message from "./Message";
import ChatWidgetSlider from "../Tiles/ChatWidgetSlider";
import { ReactComponent as Loader } from "./assets/loader.svg";

const Content = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 10px;

  .loader {
    height: 60px;

    circle {
      fill: ${(props) => `${props.color} !important`};
    }
  }
`;

const LoaderContainer = styled.div`
  margin-left: 20px;
`;

const MessagesList = ({
  messages,
  token,
  isLoading,
  avatarImg,
  SubmitForm,
  secondaryColor,
  color,
  imInterested,
  organizationTimeZone,
  organizationWorkingHours,
  organizationCustomWorkingHours,
  hideAppointmentBooking,
  scheduleTestDrive,
  submitBooking,
}) => {
  const messagesEndRef = useRef(null);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  const renderMessage = ({ message, index }) => {
    return (
      <Message
        message={message}
        secondaryColor={secondaryColor}
        avatarImg={avatarImg}
        SubmitForm={SubmitForm}
        //nextIsSent={messages[index + 1]?.role !== "user"}
        color={color}
        organizationTimeZone={organizationTimeZone}
        organizationWorkingHours={organizationWorkingHours}
        organizationCustomWorkingHours={organizationCustomWorkingHours}
        hideAppointmentBooking={hideAppointmentBooking}
        timestamp={message.timestamp}
        submitBooking={submitBooking}
      />
    );
  };

  const renderContent = (message, index) => {
    const { type, hidden } = message;

    if (hidden) return null;

    if (type === "slider") {
      return (
        <div>
          {message?.text &&
            renderMessage({
              message,
              index,
            })}

          <ChatWidgetSlider
            data={message.inventory}
            imInterested={imInterested}
            scheduleTestDrive={scheduleTestDrive}
          />
        </div>
      );
    }

    return renderMessage({
      message,
      index,
    });
  };

  return (
    <Content color={color} id="messages-container">
      {messages.map((message, index) => {
        return <div key={index}>{renderContent(message, index)}</div>;
      })}
      <div ref={messagesEndRef} />
      {isLoading && (
        <LoaderContainer>
          <Loader className="loader" />
        </LoaderContainer>
      )}
    </Content>
  );
};

export default MessagesList;
