import React from "react";
import styled from "styled-components";

import ArrowLeft from "./assets/arrowLeft.svg";
import ArrowRight from "./assets/arrowRight.svg";

const NavigationContainer = styled.div`
  position: absolute;
  z-index: 6;
  top: 45%;
  right: 23px;
  cursor: pointer;
  left: ${(props) => props.left};
  right: ${(props) => props.right};
  background: rgba(154, 154, 154, 0.2);
  border-radius: 60px;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Icon = styled.img`
  width: 8px;
  height: 16px;
`;

const NavigationIcon = ({ onClick, position }) => {
  const IconSrc = position === "left" ? ArrowLeft : ArrowRight;

  return (
    <NavigationContainer
      onClick={onClick}
      left={position === "left" && "10px"}
      right={position === "right" && "10px"}
    >
      <Icon src={IconSrc} alt={position} />
    </NavigationContainer>
  );
};

export default NavigationIcon;
