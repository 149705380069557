import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ChatHeader from "../Chat/Form/ChatHeader";
import MessagingInput from "./MessagingInput";
import MessagesList from "./MessagesList";
import MessagingDebug from "./MessagingDebug";
import SimpleLeadForm from "../Chat/Form/SimpleLeadForm";
import { useChatContext } from "../../contexts/ChatContext";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Header = styled.div`
  height: 50px;
`;

const MessagingApp = ({
  chatData,
  firstAndLastNameSeparately,
  firstName,
  lastName,
  setFirstName,
  setLastName,
  goBack,
  selectedQuestion,
  submit,
  viewIndex,
  img,
  second_message,
  organization_name,
  typingThree,
  uixFormDescription,
  answer,
  dateQuestion,
  typingTwo,
  name,
  setName,
  phoneNumber,
  setPhoneNumber,
  organizationTimeZone,
  organizationWorkingHours,
  organizationCustomWorkingHours,
  currentUrl,
}) => {
  const { secondaryColor, color, _token, image_url } = chatData;
  const [text, setText] = useState("");
  const {
    initializeChat,
    messages,
    sendMessage,
    isSending,
    chatId,
    logs,
    clearChat,
  } = useChatContext();

  useEffect(() => {
    if (chatId && messages.length === 0) {
      sendInitialMessage();
    } else if (!chatId) {
      initializeChat();
    }
  }, [chatId]);

  useEffect(() => {
    const storageListener = (event) => {
      if (event.key === "chatClosed" && event.newValue) {
        const data = JSON.parse(event.newValue);
        if (data.closed) {
          clearChat(goBack);
          goBack();
        }
      }
    };

    window.addEventListener("storage", storageListener);

    return () => {
      window.removeEventListener("storage", storageListener);
    };
  }, []);

  useEffect(() => {
    if (messages.length > 0) {
      scrollToBottom();
    }
  }, [messages]);

  const scrollToBottom = () => {
    const messagesContainer = document.getElementById("messages-container");
    if (messagesContainer) {
      messagesContainer.scrollTo({
        top: messagesContainer.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  const sendTextMessage = ({ text }) => {
    sendMessage({
      text,
      url: currentUrl,
    });
  };

  const sendInitialMessage = () => {
    sendTextMessage({
      text: selectedQuestion,
    });
  };

  const handleSubmitLeadForm = (data) => {
    const text = `My info first name: ${firstName} last name: ${lastName} phone number: ${phoneNumber}`;
    sendTextMessage({
      text,
    });
  };

  const submitBooking = ({ date, time }) => {
    const text = `I would like to book an appointment on ${date}, at ${time}.`;
    sendTextMessage({
      text,
    });
  };

  const imInterested = (vehicle) => {
    const text = `I'm interested in this vehicle vin: ${vehicle?.data?.vin}`;
    sendTextMessage({
      text,
    });
  };

  const scheduleTestDrive = (vehicle) => {
    const text = `I would like to schedule a test drive for ${vehicle?.data?.vin}`;
    sendTextMessage({
      text,
    });
  };

  const handleSend = () => {
    if (!text.trim()) return;
    setText("");
    sendTextMessage({
      text,
    });
  };

  const hideAppointmentBooking = () => {
    // remove BOOKING from messages
    // setMessages((prevMessages) => {
    //   return prevMessages.filter((message) => message.type !== "BOOKING");
    // });
  };

  const SubmitForm = () => {
    return (
      <SimpleLeadForm
        chatData={chatData}
        //submit={handleSend}
        name={name}
        setName={setName}
        phoneNumber={phoneNumber}
        setPhoneNumber={setPhoneNumber}
        firstAndLastNameSeparately={firstAndLastNameSeparately}
        firstName={firstName}
        lastName={lastName}
        setFirstName={setFirstName}
        setLastName={setLastName}
        viewIndex={viewIndex}
        img={img}
        second_message={second_message}
        organization_name={organization_name}
        color={color}
        typingThree={typingThree}
        submit={handleSubmitLeadForm}
        selectedQuestion={selectedQuestion}
        description={uixFormDescription}
        answer={answer}
        dateQuestion={dateQuestion}
        typingTwo={typingTwo}
      />
    );
  };

  const renderInputFiled = () => {
    const hasFormInMessages = messages.some(
      (message) => message.type === "FORM" && !message.hidden
    );
    if (hasFormInMessages) return null;

    return (
      <MessagingInput
        submit={handleSend}
        color={color}
        arrowColor={secondaryColor}
        disabled={isSending}
        value={text}
        handleChange={(e) => setText(e.target.value)}
        token={_token}
      />
    );
  };

  const handleGoBack = () => {
    localStorage.setItem(
      "chatClosed",
      JSON.stringify({ closed: true, timestamp: Date.now() })
    );
    clearChat();
    goBack();
  };

  return (
    <Container>
      <Header>
        <ChatHeader goBack={handleGoBack} />
      </Header>
      <MessagingDebug logs={logs} chatId={chatId} />
      <MessagesList
        messages={messages}
        color={color}
        isLoading={isSending}
        avatarImg={image_url}
        SubmitForm={SubmitForm}
        secondaryColor={secondaryColor}
        imInterested={imInterested}
        organizationTimeZone={organizationTimeZone}
        organizationWorkingHours={organizationWorkingHours}
        organizationCustomWorkingHours={organizationCustomWorkingHours}
        hideAppointmentBooking={hideAppointmentBooking}
        scheduleTestDrive={scheduleTestDrive}
        submitBooking={submitBooking}
      />
      {renderInputFiled()}
    </Container>
  );
};

export default MessagingApp;
