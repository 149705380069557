import React from 'react'
import { strings } from '../../utils/localization';

const MetaButton = ({ onClick, style, sendIcon, disabled }) => {

    return (
        <button
            className="matadot-live-chat-123789-main-button"
            style={{ ...style, opacity: disabled ? 0.5 : 1 }}
            onClick={onClick}
            disabled={disabled}
        >
            {sendIcon || strings.CHAT_TEXT_ME}
        </button>
    )
}

export default MetaButton;
