import {
  fetchFromUrlGETAsync,
  fetchFromUrlPOSTAsync,
  fetchFromUrlPATCHAsync,
} from './UrlHelper'

const fs = {
  getSingleForWidget: async (obj, location_id) => {
    const urlParams = Object.keys(obj).map((key) => {
      return { key, value: obj[key] }
    })
    const result = await fetchFromUrlGETAsync(
      '/triggers/' + location_id,
      urlParams,
    )
    return result
  },
  getPromotions: async (obj, token) => {
    const urlParams = Object.keys(obj).map((key) => {
      return { key, value: obj[key] }
    })
    const result = await fetchFromUrlGETAsync(
      '/promotions/public/token/' + token,
      urlParams,
    )
    return result
  },
  getStores: async (obj, token) => {
    const urlParams = Object.keys(obj).map((key) => {
      return { key, value: obj[key] }
    })
    const result = await fetchFromUrlGETAsync(
      '/stores/public/token/' + token,
      urlParams,
    )
    return result
  },
  getStories: async (obj, token) => {
    const urlParams = Object.keys(obj).map((key) => {
      return { key, value: obj[key] }
    })
    const result = await fetchFromUrlGETAsync(
      '/stories/public/token/' + token,
      urlParams,
    )
    return result
  },
  getAnnouncements: async (obj, token) => {
    const urlParams = Object.keys(obj).map((key) => {
      return { key, value: obj[key] }
    })
    const result = await fetchFromUrlGETAsync(
      '/announcements/public/token/' + token,
      urlParams,
    )
    return result
  },
  setSendQuestionForWidget: async (obj, token) => {
    const urlParams = Object.keys(obj).map((key) => {
      return { key, value: obj[key] }
    })
    const result = await fetchFromUrlPOSTAsync('/triggers/' + token, urlParams)
    return result
  },

  setSendTriggerId: async (triggerId) => {
    const result = await fetchFromUrlPATCHAsync(
      `/triggers/${triggerId}/display`,
      [],
    )
    return result
  },
}

const loc = {}

const chatSettingsMainActions = Object.assign(fs, loc)

export default chatSettingsMainActions
