import React from 'react'
import { SVGChatBack } from '../Icons/Icons';
import ChatHeaderQuestion from './ChatHeaderQuestion';
import styled from 'styled-components';

const ChatHeading = styled.h2`
    font-family: 'Futura PT Demi';
    font-weight: 600;
    font-size: 22px;
    color: ${(props) => props.color || '#ffffff'};
    margin: 0;
    line-height: 36px;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 36ch;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`;

const ChatHeaderWrapper = styled.div`
    padding: 23px 16px 16px 16px;
`

const ChatHeader = ({ onBackClick, selectedQuestion, viewIndex, backgroundColor, setSelectedQuestion, welcome_title, handleSelectQuestion, listPromotions, listAnnouncements }) => {

    return (
        <ChatHeaderWrapper style={{ background: backgroundColor }}>
            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start'
            }} className="matadot-live-chat-123789-h2-with-icon">
                {
                    
                    <>
                    <div
                        className="matadot-live-chat-123789-back-view"
                        onClick={onBackClick}
                        style={{
                            visibility: viewIndex === 2 ? 'visible': 'hidden',
                            cursor: 'pointer',
                            position: 'absolute',
                            top: '23px',
                            left: '16px'
                        }}
                    >
                        <SVGChatBack />
                    </div>
										<br />
                    </>
                }
                {viewIndex === 1 && <ChatHeading>{welcome_title}</ChatHeading>}
            </div>
            {
                viewIndex === 1 && (
                    <ChatHeaderQuestion
                        selectedQuestion={selectedQuestion}
                        setSelectedQuestion={setSelectedQuestion}
                        handleSelectQuestion={handleSelectQuestion}
                        backgroundColor={backgroundColor}
                        listAnnouncements={listAnnouncements}
                        listPromotions={listPromotions}
                    />
                )
            }
        </ChatHeaderWrapper>
    )
}

export default ChatHeader;