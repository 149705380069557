import React from "react";
import styled from "styled-components";
import linkIcon from "./assets/link-right.svg";

const Container = styled.div`
  border: 1px solid #e5e9f2;
  background: #fff;
  width: 250px;
  height: 100%;
  border-radius: 20px;
  background: #fff;
  height: 100%;
  padding: 16px;
`;

const AwardLink = styled.a`
  display: block;
  margin: 5px 0;
  text-decoration: none;
  border-bottom: 1px solid var(--Design-Background-Stroke, #e5e9f2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #474657;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  padding-bottom: 8px;
  padding-top: 12px;
  overflow: hidden;

  &:last-child {
    border-bottom: none;
  }
`;

const LinkText = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Title = styled.h4`
  overflow: hidden;
  color: #0e0e0e;
  text-overflow: ellipsis;
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  margin: 0;
  margin-bottom: 13px;
`;

const AwardTile = ({ item }) => {
  if (!item) return null;
  return (
    <Container key={item.id}>
      <Title>{item.data.awards.title}</Title>
      {item.data.awards.list.map((award, awardIndex) => (
        <AwardLink key={awardIndex} href={award.link} target="_blank">
          <LinkText>{award.title}</LinkText>
          <img src={linkIcon} alt="Open details" />
        </AwardLink>
      ))}
    </Container>
  );
};

export default AwardTile;
