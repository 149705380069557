import React, { memo, useEffect, useMemo } from "react";
import { useState } from "react";
import styled from "styled-components";

import { default as CloseIcon } from "./assets/closeIcon.svg";
import NavigationIcon from "./NavigationIcon";
import ProgressBar from "./ProgressBar";

const Container = styled.div`
  video {
    object-fit: cover;
  }
`;
const Header = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 3;
  padding: 16px;
  backdrop-filter: contrast(0.83);
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Label = styled.p`
  font-size: 11px;
  color: #ffffff;
  margin: 0px;
`;

const Thumbnail = styled.img`
  width: 28px;
  height: 28px;
  margin-right: 8px;
  border-radius: 14px;
  object-fit: cover;
`;

const Icon = styled.img`
  max-height: 17px;
  cursor: pointer;
  margin-left: 24px;
`;

const LeftContent = styled.div`
  display: flex;
  align-items: center;
  width: 75%;
`;

const RightContent = styled.div`
  display: flex;
  align-items: center;
`;

const Image = styled.img`
  position: absolute;
  top: 0;
  z-index: 2;
  backdrop-filter: blur(10px);
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const ImagePreview = ({
  imageUrl,
  thumbnailUrl,
  title,
  close,
  nextStory,
  previousStory,
  stories,
  activeStory,
}) => {
  const [progress, setProgress] = useState(0);
  const [nextTimeout, setNextTimeout] = useState();
  const [imageLoaded, setImageLoaded] = useState();

  useEffect(() => {
    setProgress(0);
  }, []);

  useEffect(() => {
    switchToNextStory();

    return () => {
      clearTimeout(nextTimeout);
    };
  }, [imageUrl]);

  const switchToNextStory = () => {
    setProgress(0);
    setNextTimeout(
      setTimeout(() => {
        nextStory();
        clearTimeout(nextTimeout);
      }, 7000)
    );
  };

  const instantToNextStory = () => {
    setProgress(0);
    clearTimeout(nextTimeout);
    nextStory();
  };

  const instantToPreviousStory = () => {
    setProgress(0);
    clearTimeout(nextTimeout);
    previousStory();
  };

  const onClose = () => {
    clearTimeout(nextTimeout);
    close();
  };

  const imageLoadedEvent = (data) => {
    setImageLoaded(true);
  };

  const ProgressComponent = useMemo(
    () => (
      <ProgressBar
        stories={stories}
        activeStory={activeStory}
        progress={imageLoaded ? progress : 0}
        type="image"
        imageLoaded={imageLoaded}
      />
    ),
    [stories, activeStory, progress, imageLoaded]
  );

  const ContentComponent = useMemo(() => {
    return (
      <Content>
        <LeftContent>
          {thumbnailUrl && <Thumbnail src={thumbnailUrl} alt={title} />}
          <Label id="image-title">{title}</Label>
        </LeftContent>
        <RightContent>
          <Icon alt="Close" src={CloseIcon} onClick={onClose} />
        </RightContent>
      </Content>
    );
  }, [thumbnailUrl, title, close]);

  if (!imageUrl) {
    return false;
  }

  return (
    <Container id="video-player">
      <NavigationIcon position="left" onClick={instantToPreviousStory} />
      <Header>
        {ProgressComponent}
        {ContentComponent}
      </Header>
      <Image src={imageUrl} onLoad={imageLoadedEvent} alt={title} />
      <NavigationIcon position="right" onClick={instantToNextStory} />
    </Container>
  );
};

export default memo(ImagePreview);
