import React from "react";
import styled from "styled-components";
import { strings } from "../../../utils/localization";

const FooterContainer = styled.div`
  width: 100%;
  padding-left: ${(props) => props.paddingLeft + "px"};
`;

const ConsentText = styled.div`
  margin-top: ${(props) => props.margin + "px"};
  margin-bottom: ${(props) => props.margin + "px"};
  display: inline-flex;
  align-items: center;
`;

const TermsLink = styled.a`
  color: ${(props) => props.color} !important;
  text-decoration: none;
  font-size: 9px !important;

  &:hover {
    text-decoration: underline;
  }
`;

const Checkbox = styled.input`
  width: 18px;
  height: 18px;
  margin-right: 5px;
  border-radius: 10px;

  &:checked {
    accent-color: ${(props) => props.color};
  }
`;

const SmallCheckbox = styled.input`
  width: 12px;
  height: 12px;
  margin-right: 5px;
  border-radius: 10px;

  &:checked {
    accent-color: ${(props) => props.color};
  }
`;

const Text = styled.div`
  margin: 0;
  font-size: 9px !important;
  text-align: left;
`;

const locationLinks = {
  "65b009e83772ad0013643fd0": {
    termsLink: "https://carshop.com/terms",
    privacyLink: "https://carshop.com/privacy-requests",
  },
  "65affdca96d47500136b2a3d": {
    termsLink: "https://carshop.com/terms",
    privacyLink: "https://carshop.com/privacy-requests",
  },
  "65afec12007c5a001325ea72": {
    termsLink: "https://carshop.com/terms",
    privacyLink: "https://carshop.com/privacy-requests",
  },
  "65aff26f3772ad00136433c1": {
    termsLink: "https://carshop.com/terms",
    privacyLink: "https://carshop.com/privacy-requests",
  },
  "668ebf435a165bbdf1291690": {
    termsLink: "https://carshop.com/terms",
    privacyLink: "https://carshop.com/privacy-requests",
  },
  "668eccbb543b9c192cd2de83": {
    termsLink: "https://carshop.com/terms",
    privacyLink: "https://carshop.com/privacy-requests",
  },
  "65aec4787640ce001303358b": {
    termsLink: "https://www.auditurnersville.com/penske-terms-of-use.htm",
    privacyLink:
      "https://www.auditurnersville.com/tools/complyauto/privacy-policy.htm",
  },
  "65aea1867640ce001303232f": (currentUrl) => {
    if (currentUrl.includes("cadillacofturnersville.com")) {
      return {
        termsLink:
          "https://www.cadillacofturnersville.com/terms-of-use-policies/",
        privacyLink: "https://www.cadillacofturnersville.com/privacy-policies/",
      };
    } else if (currentUrl.includes("MatadorEngagementPlatformFrontEnd")) {
      return {
        termsLink:
          "https://www.chevroletofturnersville.com/terms-of-use-policies/",
        privacyLink:
          "https://www.chevroletofturnersville.com/privacy-policies/",
      };
    } else {
      return {
        termsLink:
          "https://www.chevroletofturnersville.com/terms-of-use-policies/",
        privacyLink:
          "https://www.chevroletofturnersville.com/privacy-policies/",
      };
    }
  },
  "65aeb5c1007c5a001325afeb": {
    termsLink: "https://www.hyundaiofturnersville.com/penske-terms-of-use.htm",
    privacyLink:
      "https://www.hyundaiofturnersville.com/tools/complyauto/privacy-policy.htm",
  },
  "65aec9f9d2e9420013ac4d91": {
    termsLink: "https://www.porschemonmouth.com/terms-of-use.htm",
    privacyLink: "https://www.porschemonmouth.com/privacy.aspx",
  },
  // stg test
  "66cebf853545f773837e3c18": {
    termsLink: "https://projects.ubicross.pro/matador/terms.html",
    privacyLink: "https://projects.ubicross.pro/matador/privacy.html",
  },
};

const AcceptTermsFooter = ({
  color,
  termsLink = "http://matador.ai/?utm_source=matador%20connect%20widget&utm_medium=link&utm_campaign=widget%20referral",
  paddingLeft = 0,
  margin = 10,
  type = "default",
  locationId,
  currentUrl,
}) => {
  const language = strings.getLanguage() || "en";

  let links;
  if (locationId === "65aea1867640ce001303232f") {
    links = locationLinks[locationId](currentUrl);
  } else {
    links = locationLinks[locationId] || {};
  }

  const currentTermsLink = links.termsLink || termsLink;
  const currentPrivacyLink = links.privacyLink;

  const renderConsentText = () => {
    if (locationId === "597f6f18cf81540021044f6e") {
      return (
        <ConsentText margin={margin}>
          <Checkbox checked type="checkbox" color={color} readOnly />
          <Text>
            {strings.CONSENT_TEXT_NEW}{" "}
            <TermsLink
              color={color}
              target="_blank"
              href={termsLink}
              aria-label="terms link"
            >
              {strings.PRIVACY_POLICY}
            </TermsLink>
          </Text>
        </ConsentText>
      );
    }

    if (locationId === "65773a4c9211f000142b1eb4") {
      return (
        <ConsentText margin={margin}>
          <Checkbox checked={true} readOnly type="checkbox" color={color} />
          <Text>
            By interacting with this chat module, you acknowledge and consent to
            the recording and sharing of these communications with third party
            affiliates and non-affiliates for business and marketing purposes.
            To learn more, please visit our{" "}
            <TermsLink
              color={color}
              target="_blank"
              href="https://www.simpsongm.com/privacy.htm"
            >
              privacy policy
            </TermsLink>
          </Text>
        </ConsentText>
      );
    }

    if (locationId in locationLinks) {
      return (
        <ConsentText margin={margin}>
          <Checkbox checked type="checkbox" color={color} readOnly />
          <Text>
            You agree to receive automated promotional messages to the mobile
            number provided. Consent is not a condition of purchase. Message
            frequence varies. Reply STOP to opt out or HELP for help. Message &
            data rates apply. We may collect certain personal information when
            you text with us. By using this text module, you consent to the
            terms of our{" "}
            <TermsLink
              color={color}
              target="_blank"
              href={currentPrivacyLink}
              aria-label="Terms link"
            >
              Privacy Policy
            </TermsLink>{" "}
            and{" "}
            <TermsLink
              color={color}
              target="_blank"
              href={currentTermsLink}
              aria-label="Privacy link"
            >
              Terms of Use
            </TermsLink>
            .
          </Text>
        </ConsentText>
      );
    }

    if (type === "cta") {
      return (
        <ConsentText margin={margin}>
          <SmallCheckbox checked={true} type="checkbox" color={color} />
          <Text>
            {strings.SHORT_CONSENT}{" "}
            <TermsLink
              color={color}
              target="_blank"
              href={termsLink}
              aria-label="Terms link"
            >
              {strings.TERMS}
            </TermsLink>
          </Text>
        </ConsentText>
      );
    }

    if (language === "fr") {
      return (
        <ConsentText margin={margin}>
          <Checkbox checked type="checkbox" color={color} readOnly />
          <Text>
            J'accepte d'être contacté par SMS. J’accepte aussi les{" "}
            <TermsLink
              color={color}
              target="_blank"
              href={termsLink}
              aria-label="Terms link"
            >
              Conditions
            </TermsLink>{" "}
            d’utilisation.
          </Text>
        </ConsentText>
      );
    } else if (language === "es") {
      return (
        <ConsentText margin={margin}>
          <Checkbox checked type="checkbox" color={color} readOnly />
          <Text>
            Acepto ser contactado por SMS. También acepto los{" "}
            <TermsLink
              color={color}
              target="_blank"
              href={termsLink}
              aria-label="Terms link"
            >
              Términos
            </TermsLink>{" "}
            de uso.
          </Text>
        </ConsentText>
      );
    } else {
      return (
        <ConsentText margin={margin}>
          <Checkbox checked type="checkbox" color={color} readOnly />
          <Text>
            {strings.CONSENT_TEXT}{" "}
            <TermsLink
              color={color}
              target="_blank"
              href={termsLink}
              aria-label="terms link"
            >
              {strings.TERMS}
            </TermsLink>
          </Text>
        </ConsentText>
      );
    }
  };

  return (
    <FooterContainer paddingLeft={paddingLeft}>
      <div>{renderConsentText()}</div>
    </FooterContainer>
  );
};

export default AcceptTermsFooter;
