import {
  fetchFromUrlGETAsync,
} from './UrlHelper'

const fs = {
  getIntegratedCta: async (id) => {
    const urlParams = []
    const result = await fetchFromUrlGETAsync(
      '/cta/public/location/' + id + `?cb=${Date.now()}`,
      urlParams,
    )
    return result
  },
}

const loc = {}

const ctaMainActions = Object.assign(fs, loc)

export default ctaMainActions
