import { useEffect, useState } from "react";
import ReactGA from "react-ga4";

const TrackingEvents = {
  ASC_COMM_ENGAGEMENT: "asc_comm_engagement",
  ASC_COMM_SUBMISSION: "asc_comm_submission",
  ASC_FORM_ENGAGEMENT: "asc_form_engagement",
  ASC_FORM_SUBMISSION: "asc_form_submission",
  ASC_CTA_INTERACTION: "asc_cta_interaction",
};

const gaOptions = {
  cookieFlags: "SameSite=None;Secure",
  titleCase: false,
  send_page_view: false,
};

const transformCategory = (itemCategory) => {
  if (itemCategory === "new" || itemCategory === "used") {
    return "sales";
  }
  return itemCategory;
};

const formatTrackingNumber = (trackingNumber) => {
  if (!trackingNumber) {
    return [];
  }
  return trackingNumber.split(/[\s,]+/).map((id) => id.trim());
};

const useAnalytics = (trackingNumber) => {
  const [ctaInteractionSent, setCtaInteractionSent] = useState(false);
  const [formEngagementSent, setFormEngagementSent] = useState(false);

  useEffect(() => {
    const trackingNumbers = formatTrackingNumber(trackingNumber);

    if (!ReactGA?.isInitialized && trackingNumbers?.length > 0) {
      const data = trackingNumbers.map((trackingId) => {
        return {
          trackingId,
          gaOptions,
        };
      });

      ReactGA.initialize(data);

      trackingNumbers.forEach((trackingId) => {
        window.gtag("config", trackingId, {
          send_page_view: false,
        });
      });
    }
  }, [trackingNumber]);

  const sendCommEngagementEvent = ({ widget, status = "start" }) => {
    sendEvent({ name: TrackingEvents.ASC_COMM_ENGAGEMENT, widget, status });
  };

  const sendCommSubmissionEvent = ({
    widget,
    status = "start",
    itemCategory,
  }) => {
    const category = transformCategory(itemCategory);
    sendEvent({
      name: TrackingEvents.ASC_COMM_SUBMISSION,
      widget,
      status,
      itemCategory: category,
    });
  };

  const sendFormEngagementEvent = ({ widget }) => {
    if (!formEngagementSent) {
      setFormEngagementSent(true);
      sendEvent({ name: TrackingEvents.ASC_FORM_ENGAGEMENT, widget });
    }
  };

  const sendFormSubmissionEvent = ({ widget }) => {
    sendEvent({ name: TrackingEvents.ASC_FORM_SUBMISSION, widget });
  };

  const sendCtaInteractionEvent = ({ widget }) => {
    if (!ctaInteractionSent) {
      setCtaInteractionSent(true);
      sendEvent({ name: TrackingEvents.ASC_CTA_INTERACTION, widget });
    }
  };

  const sendEvent = ({ name, widget, status, itemCategory }) => {
    if (ReactGA.isInitialized) {
      ReactGA.event(name, {
        comm_type: widget,
        event_owner: "matador",
        ...(status ? { status } : {}),
        ...(itemCategory ? { item_category: itemCategory } : {}),
      });
    }
  };

  const d2cUtmTracking = (locationId) => {
    const d2cLocationIds = [
      "66423a2154c0270013a7d589",
      "6671c35e39b7f088eca4ef9c",
    ];

    if (d2cLocationIds.includes(locationId)) {
      window.parent.postMessage("userProvidedContactInfod2c", "*");
    }
  };

  return {
    sendCommEngagementEvent,
    sendCommSubmissionEvent,
    sendFormEngagementEvent,
    sendFormSubmissionEvent,
    sendCtaInteractionEvent,
    d2cUtmTracking,
  };
};

export default useAnalytics;
