import React from "react";
import styled from "styled-components";
import { ReactComponent as ArrowRight } from "./assets/arrow_right.svg";

const Container = styled.div`
  border-radius: 8px;
  padding: 8px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  :hover {
    background: #f6f7fb;
  }
`;

const Label = styled.div`
  font-size: 14px;
  color: #222222;
  line-height: 24px;
  padding-right: 8px;
  font-weight: ${({ isBold }) => (isBold ? "600" : "400")};
`;

const QuestionItem = ({ question, token, onClick, index, isBold }) => {
  return (
    <Container onClick={onClick}>
      <Label
        role="button"
        className="question-item-text-display"
        tabIndex={index}
        isBold={isBold}
      >
        {question}
      </Label>
      <ArrowRight />
    </Container>
  );
};

export default QuestionItem;
