import React, { memo, useMemo } from 'react';
import styled from 'styled-components';
import LinearProgressBar from './LinearProgress';

const LineContainer = styled.div`
	width: 100%;
	background: ${(props) =>
    props.isActive
      ? `linear-gradient(to right, #fff 0% ,#fff ${props.progress}%, rgba(255, 255, 255, 0.6) ${props.progress}%, rgba(255, 255, 255, 0.6) 100%)`
      : 'rgba(255, 255, 255, 0.6)'};
	height: 2px;
	margin-right: 5px;
`;

const Container = styled.div`
	width: 100%;
	display: flex;
	margin-left: 5px;
	height: 15px;

	.bar {
		height: 2px;
		width: 100%;
		background-color: rgba(255, 255, 255, 0.6);
		margin-right: 5px;

		.in {
			animation: ${(props) => (props.imageLoaded ? 'fill 7s linear 1;' : 'none')};
			height: 100%;
			background-color: #fff;
		}
	}

	@keyframes fill {
		0% {
			width: 0%;
		}
		100% {
			width: 100%;
		}
	}
`;

const ProgressBar = ({ stories, activeStory, progress, type, imageLoaded }) => {
  const lines = useMemo(() => {
    return stories.map((story) => {
      if (activeStory.id === story.id) {
        if (type === 'image') {
          return (
            <div key={`${story.id}-first`} className="bar">
              <div className="in" />
            </div>
          );
        }
        return (
          <LinearProgressBar key={`${story.id}-zero`} progress={parseInt(progress)} />
        );
      }
      return <LineContainer key={`${story.id}-third`} progress={parseInt(progress)} isActive={false} />;
    });
  }, [activeStory, progress, stories, type]);

  return (
    <Container imageLoaded={imageLoaded}>
      {lines}
    </Container>
  );
};

export default memo(ProgressBar);
