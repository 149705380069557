import React from "react";
import styled from "styled-components";
import { strings } from "../../utils/localization";

import { ReactComponent as MetadorIcon } from "../../assets/images/common/metadorIcon.svg";

const FooterText = styled.span`
  font-family: "Futura PT Book";
  letter-spacing: 0px;
  color: ${(props) => props.color || "#7C7C7C"};
  font-size: ${(props) => props.fontSize || "12px"};
`;

const FooterHeading = styled.p`
  font-weight: 500;
  letter-spacing: 0px;
  color: ${(props) => props.color || "#000000"};
  font-size: 16px;
  margin: 0;
  margin-right: 6px;
`;

const Wrapper = styled.div`
  text-align: center;
  padding-top: 16px;
  padding-bottom: 10px;
  font-size: 0;
  background: white;
`;

const DefaultFooter = ({ viewIndex }) => {
  return (
    <Wrapper>
      <div
        style={{
          marginBottom: 8,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <FooterHeading>{strings.POWERED_BY}</FooterHeading>
        <a
          href="http://matador.ai/?utm_source=matador%20connect%20widget&utm_medium=link&utm_campaign=widget%20referral"
          target="_blank"
          className="matadot-live-chat-123789-app-name"
          aria-label="Matador.ai link"
        >
          <MetadorIcon height={25} width={95} />
        </a>
      </div>

      {viewIndex === 1 && (
        <>
          <FooterText>
            {strings.USE_AS_SUBJECT_TO}
            <a
              target="_blank"
              aria-label="terms and conditions link"
              style={{ textDecoration: "none" }}
              href="http://matador.ai/?utm_source=matador%20connect%20widget&utm_medium=link&utm_campaign=widget%20referral"
            >
              <FooterText> | {strings.TERMS} </FooterText>
            </a>
          </FooterText>
          <FooterText>| Text message fees may apply</FooterText>
        </>
      )}

      {viewIndex === 2 && (
        <FooterText>
          {strings.BY_SUBMITTING_YOU_AGREE_TO_RECEIVE_TEXT_MESSAGES_AT_THE}
          <br />
          {strings.NUMBER_PROVIDED} {strings.MESSAGE_DATA_RATES_APPLY}
        </FooterText>
      )}
      <br />
    </Wrapper>
  );
};

export default DefaultFooter;
