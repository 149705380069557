import React, { createContext, useContext, useState } from "react";

const DataContext = createContext();

export const ContextDataProvider = ({ children }) => {
  const [chatData, setChatData] = useState();
  const [stories, setStories] = useState([]);

  return (
    <DataContext.Provider
      value={{ chatData, setChatData, stories, setStories }}
    >
      {children}
    </DataContext.Provider>
  );
};

export const useContextData = () => useContext(DataContext);
