import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const PopupPhoneField = ({
  initialCountry,
  handleChange,
  getPhoneNumberFromClipboard,
  value,
}) => {
  const [fieldTouched, setFieldTouched] = useState(false);

  const getValue = () => {
    if (initialCountry?.toLowerCase() === "au" && !value && !fieldTouched) {
      return "+04";
    }
    return value;
  };

  const onChangeHandler = (value) => {
    setFieldTouched(true);
    if (initialCountry?.toLowerCase() === "au" && !value && !fieldTouched) {
      return handleChange(`+04${value}`);
    }
    return handleChange(value);
  };

  useEffect(() => {
    document.querySelectorAll(".popup_input")[0] &&
      document
        .querySelectorAll(".popup_input")[0]
        .addEventListener("paste", getPhoneNumberFromClipboard);
  }, []);

  return (
    <PhoneInput
      country={initialCountry || "us"}
      onlyCountries={["us", "ca", "au"]}
      inputClass="popup_input"
      buttonClass="popup_input_flag"
      placeholder="(000) 000-0000"
      onChange={onChangeHandler}
      value={getValue()}
      disableCountryCode={initialCountry === "au"}
      masks={{ au: "(..) .... ...." }}
      countryCodeEditable
      disableDropdown
    />
  );
};

export default PopupPhoneField;
