import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ChatBox from "./components/Chat/ChatBox";
import ErrorPage from "./components/ErrorPage";
import TextMeView from "./views/TextMe";
import Popup from "./components/Popup/Popup";
import ChatLayout from "./v2/components/Chat/ChatLayout";
import CtaView from "./views/CtaView";
import MobileCoupon from "./views/MobileCoupon";
import DesktopCoupon from "./views/DesktopCoupon";
import MobileCta from "./views/MobileCta";
import SubscriberGrowth from "./views/SubscriberGrowth";
import WizLayout from "./v2/components/Chat/Wiz/WizLayout";
import { ContextDataProvider } from "./v2/contexts/ContextDataProvider";
import { ChatProvider } from "./v2/contexts/ChatContext";

function App() {
  return (
    <ChatProvider>
      <ContextDataProvider>
        <Router>
          <Routes>
            <Route path="/coupon/:token/:lang" element={<MobileCoupon />} />
            <Route
              path="/mobile-coupon/:token/:lang"
              element={<MobileCoupon />}
            />
            <Route
              path="/desktop-coupon/:token/:lang"
              element={<DesktopCoupon />}
            />
            <Route
              path="/chat_widget/:theme=default/:feature=text-me/:token/:lang/:locationId?"
              element={<TextMeView theme="default" />}
            />
            <Route
              path="/chat_widget/:theme=light/:feature=text-me/:token/:lang/:locationId?"
              element={<TextMeView theme="light" />}
            />
            <Route
              path="/chat_widget/:theme=default/:token/:lang"
              element={<ChatLayout theme="default" />}
            />
            <Route
              path="/wiz_widget/:theme=default/:token/:lang"
              element={<WizLayout theme="default" />}
            />
            <Route
              path="/chat_widget/:theme=light/:token/:lang"
              element={<ChatBox theme="light" />}
            />
            <Route path="/cta/:locationId/:lang" element={<CtaView />} />
            <Route path="/popup/:token/:lang" element={<Popup />} />
            <Route path="/mobile-cta/:token/:lang" element={<MobileCta />} />
            <Route
              path="/subscriber-growth/:token/:lang"
              element={<SubscriberGrowth />}
            />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </Router>
      </ContextDataProvider>
    </ChatProvider>
  );
}

export default App;
