import { fetchFromUrlGETAsync } from './UrlHelper';

const urlCoupon = "/coupons/location";

const fs = {
    getCoupon: async (locationId) => {
        const urlParams = [];
        return await fetchFromUrlGETAsync(urlCoupon + "/" + locationId, urlParams);
    },
};

const loc = {};

const couponMainActions = Object.assign(fs, loc);

export default couponMainActions;