import React from 'react';

import './LinearProgress.css'

const LinearProgressBar = ({ progress }) => {
  return (
    <div className={'mainProgressBarDiv'}>
      <div className={'emptyProgressBar'} style={{ width: '100%' }}>
        <div
          className={'fillingProgressBar'}
          style={{
            left: progress - 95 + '%',
            transition: '2s'
          }}
        />
      </div>
    </div>
  );
};

export default LinearProgressBar;
