import React, { useEffect, useState } from "react";
import { strings } from "../../utils/localization";
import { Iconset, SVGMessageRecived } from "../Icons/Icons";
import Linkify from "linkify-react";

const ChatContent = ({
  viewIndex,
  color,
  backgroundColor,
  phoneNumber,
  selectedQuestion,
  dateQuestion,
  typingTwo,
  img,
  location_name,
  first_message,
  isClientUIX,
  questionType,
  answer,
  scrollToBottom,
}) => {
  const [additionalTyping, setAdditionalTyping] = useState(true);
  const [additionalAnswerVisible, setAdditionalAnswerVisible] = useState();

  useEffect(() => {
    if (!typingTwo && questionType === "SMART_ANSWER") {
      setAdditionalAnswerVisible(true);
      setTimeout(() => {
        setAdditionalTyping(true);
        setTimeout(() => {
          setAdditionalTyping(false);
          scrollToBottom();
        }, 2000);
      }, 2000);
    }
  }, [typingTwo]);

  const renderMessages = () => {
    if (questionType === "SMART_ANSWER") {
      return (
        <>
          <div className="matadot-live-chat-123789-chat-message-wrappper matadot-live-chat-123789-left">
            {typingTwo ? (
              <div className="matadot-live-chat-123789-dotsContainerTwo">
                {Iconset.typing}
              </div>
            ) : (
              renderAnswer()
            )}
          </div>

          {additionalAnswerVisible && (
            <div className="matadot-live-chat-123789-chat-message-wrappper matadot-live-chat-123789-left">
              {additionalTyping ? (
                <div className="matadot-live-chat-123789-dotsContainerTwo">
                  {Iconset.typing}
                </div>
              ) : (
                renderAnswer(false)
              )}
            </div>
          )}
        </>
      );
    }

    return (
      <div className="matadot-live-chat-123789-chat-message-wrappper matadot-live-chat-123789-left">
        {typingTwo ? (
          <div className="matadot-live-chat-123789-dotsContainerTwo">
            {Iconset.typing}
          </div>
        ) : (
          renderAnswer()
        )}
      </div>
    );
  };

  const renderAnswer = (defaultMessage = true) => {
    let textMessage = first_message;

    if (questionType === "SMART_ANSWER") {
      if (defaultMessage) {
        textMessage = answer;
      } else {
        textMessage =
          "To answer any other questions, please enter your phone number below.";
      }
    }

    return (
      <div className="matadot-live-chat-123789-message-with-image">
        <div className="matadot-live-chat-123789-img-wrapper">
          {img ? (
            <img
              alt="Attachment"
              className="matadot-live-chat-123789-img"
              src={img}
            />
          ) : (
            Iconset.noImage
          )}
        </div>
        <div className="matadot-live-chat-123789-message">
          <div className="matadot-live-chat-123789-phone">
            {!isClientUIX && location_name}
          </div>
          <div className="matadot-live-chat-123789-text">
            <Linkify options={{ target: "_blank" }}>{textMessage}</Linkify>
          </div>
          <div className="matadot-live-chat-123789-date">{dateQuestion}</div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="matadot-live-chat-123789-chat-message-wrappper matadot-live-chat-123789-right">
        <div
          className="matadot-live-chat-123789-message"
          style={{ backgroundColor }}
        >
          {viewIndex === 3 && (
            <div className="matadot-live-chat-123789-phone">{phoneNumber}</div>
          )}
          <div className="matadot-live-chat-123789-text">
            {selectedQuestion}
          </div>
          <div className="matadot-live-chat-123789-date">{dateQuestion}</div>
        </div>
        <div className="matadot-live-chat-123789-message-received">
          <div>
            <SVGMessageRecived color={color} />
          </div>
          {strings.TEXT_RECEIVED}
        </div>
      </div>
      {renderMessages()}
    </>
  );
};

export default ChatContent;
