import { fetchFromUrlGETAsync } from "./UrlHelper";

const fs = {
  getData: async (locationId) => {
    const urlParams = [];
    return await fetchFromUrlGETAsync(
      `/locations/${locationId}/textmedata`,
      urlParams
    );
  },
};

const loc = {};

const mobileCtaActions = Object.assign(fs, loc);

export default mobileCtaActions;
