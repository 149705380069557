import { fetchFromUrlGETAsync } from "./UrlHelper";

const fs = {
  getData: async (locationId) => {
    const urlParams = [];
    return await fetchFromUrlGETAsync(
      `/subscriber-growth/location/${locationId}`,
      urlParams
    );
  },
};

const loc = {};

const subscriberGrowthActions = Object.assign(fs, loc);

export default subscriberGrowthActions;
