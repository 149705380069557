import React, { useEffect, useState } from "react";
import styled from "styled-components";
import AcceptTermsFooter from "../../Footer/AcceptTermsFooter";
import { validatePhoneNumber } from "../../../../utils/validation";
import InitialResponse from "./InitialResponse";
import { strings } from "../../../../utils/localization";

import "../../../../styles/LiveChatStyle.css";
import WeGotYourMessage from "./WeGotYourMessage";
import ChatHeader from "./ChatHeader";
import { Iconset } from "../../../../components/Icons/Icons";
import ModernPhoneNumberField from "../../../../components/Fields/ModernPhoneNumberField";
import ModernInput from "../../Input/ModernInput";
import ModerSubmitButton from "../../../../components/Buttons/ModerSubmitButton";

const Container = styled.form`
  max-width: 400px;
  margin: 0 auto;
  padding: 10px 15px 30px 15px;

  .matadot-live-chat-123789-main-input {
    width: 100%;
    height: 46px;
    border-radius: 5px !important;
  }

  .flag-dropdown {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
`;

const Note = styled.p`
  margin-top: -5px;
  margin-bottom: 5px;
  font-size: 12px;
  color: #c43423;
  margin-bottom: 2px;
`;

const FormContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;

const Form = styled.div`
  border-radius: 14px 14px 0px 14px;
  margin-bottom: 10px;

  .matadot-live-chat-123789-main-input {
    background-color: #f9f9f9;
    border-bottom-width: 1.3px;
    border-top: 0px;
    border-right: 0px;
    border-radius: 0px;
    color: #17181b;
  }

  .matadot-live-chat-123789-main-input:focus {
    border-bottom-color: ${({ primaryColor }) => primaryColor};
  }
`;

const Header = styled.div`
  height: 50px;
`;

const Avatar = styled.img`
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  border-radius: 16px;
`;

const RightContent = styled.div`
  background-color: #fafbfd;
  padding: 12px;
  border-radius: 16px;
  max-width: 80%;
  margin-left: 8px;
`;

const EmptyAvatar = styled.div`
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  border-radius: 16px;
  background-color: #f9f9f9;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LeadSubmitForm = ({
  chatData,
  submit,
  selectedQuestion,
  setSelectedQuestion,
  name,
  setName,
  phoneNumber,
  setPhoneNumber,
  description,
  answer,
  dateQuestion,
  typingTwo,
  viewIndex,
  questionType,
  typingThree,
  isCustomSubmitForm,
  firstAndLastNameSeparately,
  firstName,
  lastName,
  setFirstName,
  setLastName,
  goBack,
  currentUrl,
}) => {
  const [isFormValid, setIsFormValid] = useState(false);
  const [mobileNumberError, setMobileNumberError] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const { isCustomerNameRequired } = chatData || {};

  let organization_name =
    chatData && chatData.organization_name ? chatData.organization_name : "";
  let backgroundColor = chatData && chatData.color ? chatData.color : "red";
  let first_message =
    chatData && chatData.texts.first_message
      ? chatData.texts.first_message
      : "";
  let second_message =
    chatData && chatData.texts.second_message
      ? chatData.texts.second_message
      : "";
  let color = chatData && chatData.color ? chatData.color : "white";
  let img = chatData && chatData.image_url ? chatData.image_url : "";

  useEffect(() => {
    setIsFormValid(
      !mobileNumberError && phoneNumber && !messageError && selectedQuestion
    );
  }, [mobileNumberError, phoneNumber, messageError, selectedQuestion]);

  const handleNameChange = (value) => {
    setName(value);
  };

  const handleMobileNumberChange = (value) => {
    setPhoneNumber(value);
    validatePhone({ value });
  };

  const handleMessageChange = (e) => {
    setSelectedQuestion(e.target.value);
    validateMessage({ value: e.target.value });
  };

  const checkNameValidation = () => {
    let valid = true;

    if (isCustomerNameRequired) {
      if (firstAndLastNameSeparately) {
        valid = firstName.trim() !== "" && lastName.trim() !== "";
      } else {
        valid = name.trim() !== "";
      }
    }

    if (!valid) {
      setTimeout(() => {
        scrollToBottom();
      }, 100);
    }

    return valid;
  };

  const handleSubmit = (e) => {
    const isNameValid = checkNameValidation();

    setFormSubmitted(true);
    e.preventDefault();
    if (!isFormValid || !isNameValid) return;
    submit({ viewIndex: 3, send: true });
  };

  const validatePhone = ({ value }) => {
    const text = value || phoneNumber;
    const isMobileNumberValid = text.trim() !== "" && validatePhoneNumber(text);
    setMobileNumberError(!isMobileNumberValid);
  };

  const validateMessage = ({ value }) => {
    const text = value || selectedQuestion;

    const isMessageValid = text.trim() !== "";
    setMessageError(!isMessageValid);
  };

  const getPhoneNumberFromClipboard = (event) => {
    let pasteValue = (event.clipboardData || window.clipboardData).getData(
      "text"
    );
    pasteValue = pasteValue.replace(/-|\s/g, "");
    pasteValue = pasteValue.replace("(", "");
    pasteValue = pasteValue.replace(")", "");
    pasteValue = pasteValue.replace("tel:", "");
    pasteValue = pasteValue.replace("+", "");

    if (chatData?.organization_country_code?.toLowerCase() === "au") {
      if (pasteValue.length === 9) {
        pasteValue = `0${pasteValue}`;
      }
      if (pasteValue.length >= 11) {
        pasteValue = `0${pasteValue && pasteValue.slice(2)}`;
      }
    } else {
      if (pasteValue.length === 10) {
        pasteValue = `1${pasteValue}`;
      }
      if (pasteValue.length === 11) {
        pasteValue = `1${pasteValue && pasteValue.slice(1)}`;
      }
    }

    validatePhone({ value: pasteValue });
    setPhoneNumber(pasteValue);
  };

  const scrollToBottom = () => {
    const el = document.getElementById("matador-connect-main-content");
    if (el) {
      el.scrollTop = el.scrollHeight;
    }
  };

  const renderName = () => {
    if (firstAndLastNameSeparately) {
      return (
        <>
          <ModernInput
            placeholder={strings.FIRST_NAME}
            color={backgroundColor}
            value={firstName}
            backgroundColor="#fff"
            onChange={(value) => setFirstName(value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSubmit();
              }
            }}
            onFocus={scrollToBottom}
            required
            enableValidation={isCustomerNameRequired}
            formSubmitted={formSubmitted}
            borderDisabled
            marginBottom={12}
          />
          <ModernInput
            placeholder={strings.LAST_NAME}
            color={backgroundColor}
            value={lastName}
            backgroundColor="#fff"
            onChange={(value) => setLastName(value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSubmit();
              }
            }}
            onFocus={scrollToBottom}
            required
            enableValidation={isCustomerNameRequired}
            formSubmitted={formSubmitted}
            borderDisabled
          />
          <div style={{ height: 8 }}></div>
        </>
      );
    }

    return (
      <>
        <ModernInput
          placeholder={strings.FULL_NAME}
          color={backgroundColor}
          value={name}
          backgroundColor="#fff"
          onChange={handleNameChange}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSubmit();
            }
          }}
          onFocus={scrollToBottom}
          required
          enableValidation={isCustomerNameRequired}
          formSubmitted={formSubmitted}
          borderDisabled
          marginBottom={12}
        />
      </>
    );
  };

  const renderContent = () => {
    if (viewIndex === 3) {
      return (
        <WeGotYourMessage
          typingThree={typingThree}
          img={img}
          organization_name={organization_name}
          color={color}
          second_message={second_message}
          backgroundColor={backgroundColor}
          secondaryColor={chatData.secondaryColor}
        />
      );
    }

    return (
      <FormContainer>
        {img ? (
          <Avatar src={img} alt="User Avatar" />
        ) : (
          <EmptyAvatar>{Iconset.noImageDefault}</EmptyAvatar>
        )}

        <RightContent>
          <Form primaryColor={chatData.color}>
            {renderName()}

            <ModernPhoneNumberField
              value={phoneNumber}
              onChange={(value) => handleMobileNumberChange(value)}
              defaultCountryFlag={
                chatData?.organization_country_code?.toLowerCase() || "us"
              }
              getPhoneNumberFromClipboard={getPhoneNumberFromClipboard}
              token={chatData?._token}
            />

            {formSubmitted && (
              <Note
                style={{
                  display: mobileNumberError ? "block" : "none",
                  marginTop: 3,
                  marginBottom: 5,
                }}
              >
                {strings.REQUIRED_FIELD}
              </Note>
            )}
          </Form>
          <AcceptTermsFooter
            color={chatData.termsLink}
            termsLink={chatData.termsLink}
            margin={0}
            locationId={chatData._location_id}
            currentUrl={currentUrl}
          />
          <ModerSubmitButton
            disabled={!isFormValid}
            marginTop={18}
            color={chatData.color}
            secondaryColor={chatData.secondaryColor}
          />
        </RightContent>
      </FormContainer>
    );
  };

  return (
    <Container onSubmit={handleSubmit}>
      <Header>
        <ChatHeader goBack={goBack} />
      </Header>
      <InitialResponse
        img={img}
        dateQuestion={dateQuestion}
        selectedQuestion={selectedQuestion}
        answer={answer}
        firstMessage={first_message}
        questionType={questionType}
        primaryColor={chatData.color}
        isCustomSubmitForm={isCustomSubmitForm}
        secondaryColor={chatData.secondaryColor}
      />

      {renderContent()}
    </Container>
  );
};

export default LeadSubmitForm;
