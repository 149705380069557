import React from "react";
import styled from "styled-components";
import QuestionItem from "./QuestionItem";
import { ReactComponent as BackIcon } from "../Form/assets/back-icon.svg";

const Container = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: flex-end;
  background-color: #fff;
  box-shadow: 2px 2px 15px 0px rgba(83, 85, 128, 0.1);
  border-radius: 16px;
  padding: 12px;
  background-color: #fff;
`;

const Item = styled.div`
  cursor: pointer;
  margin-top: 10px;
  width: 100%;
  text-align: center;

  .question-item-text-display {
    font-size: 13.5px;
  }

  :nth-child(1) {
    margin-top: 0px;
  }
`;

const BackCointainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  width: 100%;
`;

const LocationsList = ({
  locations,
  onLocationClick,
  language,
  goBack,
  isBold,
}) => {
  return (
    <Container>
      {goBack && (
        <BackCointainer onClick={goBack}>
          <BackIcon />
        </BackCointainer>
      )}
      {locations.map((item, index) => (
        <Item key={`${item.locationId}-${index}`}>
          <QuestionItem
            onClick={() => onLocationClick(item)}
            question={item.locationCustomName?.[language] || item.location.name}
            index={index}
            isBold={isBold}
          />
        </Item>
      ))}
    </Container>
  );
};

export default LocationsList;
