import React from "react";
import styled from "styled-components";
import mileageIcon from "./assets/mileage.svg";
import upIcon from "./assets/up.svg";
import { strings } from "../../../utils/localization";
import CalendarImage from "./assets/calendar.png";
import vehiclePlaceholder from "./assets/vehiclePlaceholder.png";

const Container = styled.div`
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  border: 1px solid #e5e9f2;
  background: #fff;
  padding: 8px;
  display: flex;
  flex-direction: column;
  width: 250px;
  height: 100%;
`;

const CarImage = styled.img`
  width: 229px;
  height: 174px;
  border-radius: 12px;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 50.14%,
    rgba(47, 53, 51, 0.18) 99.79%
  );
  object-fit: cover;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
`;

const CarDetails = styled.div`
  flex-grow: 1;
  margin-top: 5px;
  width: 100%;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Title = styled.p`
  overflow: hidden;
  color: #0e0e0e;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
  text-transform: capitalize;
`;

const Price = styled.p`
  color: #3b48e6;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin: 0;
`;

const Tag = styled.div`
  color: #77808d;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
  border-radius: 6px;
  background: #f6f7fb;
  padding: 2px 4px;
  margin-right: 4px;
  margin-bottom: 10px;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 8px;
`;

const MileageIcon = styled.img`
  height: 14px;
  width: 14px;
  margin-right: 8px;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;

  > span {
    flex: 1;
    &:not(:last-child) {
      margin-right: 6px;
    }
  }
`;

const Button = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  padding: 6px 0px;
  border-radius: 16px;
  border: 1px solid #e5e9f2;
  display: flex;
  justify-content: center;
  white-space: nowrap;
  align-items: center;

  /* &:first-child {
    flex: 1.2;
  }

  &:last-child {
    flex: 0.8;
  } */
`;

const Text = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  white-space: nowrap;
  color: #0e0e0e;
  margin-left: 4px;
`;

const SuccesText = styled.span`
  color: #006b00;

  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  text-transform: uppercase;
`;

const ImageWrapper = styled.div`
  position: relative;
`;

const ActiveConversations = styled.p`
  color: #474657;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0.2px;
  border-radius: 8px;
  border: 1px solid rgba(234, 153, 87, 0.3);
  background: #fff5ea;
  position: absolute;
  bottom: 0px;
  left: 4px;
  padding: 4px 6px;
`;

const InterestedButton = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #3b48e6;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  font-size: 15px;
  cursor: pointer;
  text-align: center;
  margin-right: 12px;

  &:hover {
    background-color: #2e3ab7;
  }
`;

const BottomWrapper = styled.div`
  margin-top: 16px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const CalendarIcon = styled.img`
  width: 35px;
  height: 35px;
  cursor: pointer;
`;

const CarTile = ({ item, setActiveItem, imInterested, scheduleTestDrive }) => {
  const setAward = () => {
    setActiveItem(item);
  };

  const handleInterestClick = () => {
    imInterested(item);
  };

  const formatPrice = () => {
    const price = item.data.price;
    if (!price) {
      return strings.PRICE_PENDING;
    }
    const formattedPrice =
      "$" + price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return formattedPrice;
  };

  const renderRating = () => {
    const hasGreatRating = item.data?.dealRatings.some(
      (deal) => deal.rating === "GREAT"
    );

    if (!hasGreatRating) {
      return null;
    }

    return (
      <Button color="#006B00">
        <span style={{ display: "flex" }} role="img" aria-label="Great">
          <img src={upIcon} alt="Great" />
        </span>
        <SuccesText>{strings.GREAT_DEAL}</SuccesText>
      </Button>
    );
  };

  return (
    <Container key={item.id}>
      <ImageWrapper>
        <CarImage
          src={item.data.imageUrl}
          alt={item.data.title}
          onError={(e) => {
            e.target.src = vehiclePlaceholder;
          }}
        />
        <ActiveConversations>
          <span role="img" aria-label="Fire">
            🔥
          </span>{" "}
          {item.data.active_conversations} {strings.ACTIVE_CONVERSATIONS}
        </ActiveConversations>
      </ImageWrapper>
      <CarDetails>
        <TitleContainer>
          <Title>{item.data.title}</Title>
          <Price>{formatPrice()}</Price>
        </TitleContainer>

        <Tags>
          <Tag>#{item.data.stockNumber}</Tag>
          {/* <Tag>{item.data.code2}</Tag> */}
          <Tag>
            <MileageIcon src={mileageIcon} alt="mileage" />
            {item.data.mileage}
          </Tag>
        </Tags>

        <Buttons>
          {renderRating()}
          {/* <Button onClick={setAward}>
            <span role="img" aria-label="Awards">
              🏆
            </span>
            <Text>{strings.AWARDS}</Text>
          </Button> */}
        </Buttons>
        <BottomWrapper>
          <InterestedButton onClick={handleInterestClick}>
            {strings.I_AM_INTERESTED}
          </InterestedButton>
          <CalendarIcon
            src={CalendarImage}
            onClick={() => scheduleTestDrive(item)}
            alt="calendar"
          />
        </BottomWrapper>
      </CarDetails>
    </Container>
  );
};

export default CarTile;
