import React from "react";
import styled from "styled-components";
import { strings } from "../../utils/localization";
import { Iconset } from "../Icons/Icons";

const Container = styled.button`
  background: ${(props) => props.bgColor};
  border-radius: 60px;
  padding: 10px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  width: 100%;
  margin-top: ${(props) => props.marginTop + "px"};
  border: none;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  cursor: pointer;
  color: ${(props) => props.color};
`;

const Icon = styled.div`
  margin-left: 6px;

  path {
    fill: ${(props) => props.fill};
  }
`;

const ModerSubmitButton = ({
  onClick,
  style,
  marginTop,
  disabled,
  color,
  secondaryColor,
}) => {
  return (
    <Container
      style={style}
      onClick={onClick}
      disabled={disabled}
      type="submit"
      marginTop={marginTop}
      bgColor={color}
      color={secondaryColor}
    >
      {strings.SEND}
      <Icon fill={secondaryColor}>{Iconset.sendMessage}</Icon>
    </Container>
  );
};

export default ModerSubmitButton;
