import React from 'react'

import styled from 'styled-components'
import { strings } from '../../utils/localization'

const SubmitTextStyle = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: ${(props) =>
    props.enableSubmit ? props.backgroundColor : '#D1D2DE'};
  padding: 8px 18px;
  border-radius: 19px;
  color: #ffffff;
  font-family: 'Roboto';
  cursor: pointer;
`

const SubmitText = ({
  id,
  onClick,
  enableSubmit,
  text,
  backgroundColor = '#706AFF',
}) => {
  return (
    <SubmitTextStyle
      backgroundColor={backgroundColor}
      id={id}
      onClick={onClick}
      enableSubmit={enableSubmit}
    >
      {text || strings.CHAT_TEXT_ME}
    </SubmitTextStyle>
  )
}

export default SubmitText