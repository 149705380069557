export const invalidPhoneNumbers = [
  "10000000000",
  "10000000000",
  "11111111111",
  "12222222222",
  "13333333333",
  "14444444444",
  "15555555555",
  "16666666666",
  "17777777777",
  "18888888888",
  "19999999999",
  "00000000000",
  "12345678901",
  "1234567890",
  "2125551234",
  "4165551234",
  "6045551234",
  "2025551234",
  "3125551234",
  "2135551234",
  "7025551234",
  "3055551234",
  "5555555555",
  "9999999999",
];
