import React from "react";

import styled from "styled-components";
import { strings } from "../../../utils/localization";

const SubmitTextStyle = styled.div`
  position: absolute;
  right: 10px;
  top: 3px;
  background-color: ${(props) =>
    props.enableSubmit ? props.backgroundColor : "#D1D2DE"};
  padding: 6px 10px;
  border-radius: 19px;
  color: #ffffff;
  cursor: pointer;
  font-size: 15px;
`;

const SubmitText = ({
  id,
  onClick,
  enableSubmit,
  text,
  backgroundColor = "#706AFF",
}) => {
  return (
    <SubmitTextStyle
      backgroundColor={backgroundColor}
      id={id}
      onClick={onClick}
      enableSubmit={enableSubmit}
    >
      {text || strings.CHAT_TEXT_ME}
    </SubmitTextStyle>
  );
};

export default SubmitText;
