import React, { useCallback } from "react";
import styled from "styled-components";
import { strings } from "../../../../utils/localization";
import isStoreOpen from "./store-time-helper";

const Container = styled.div`
  border-radius: 12px;
  display: flex;
  padding: 4px 10px;
  align-self: center;
  align-items: center;
  border-radius: 100px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: rgba(255, 255, 255, 0.12);
  width: fit-content;
  margin-top: 8px;
`;

const Label = styled.div`
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  color: ${(props) => props.color};
`;

const Circle = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background: ${(props) => props.backgroundColor};
  margin-right: 10px;
`;

const StoreStatus = ({
  organizationTimeZone,
  organizationWorkingHours,
  organizationCustomWorkingHours,
  token,
  secondaryColor,
}) => {
  const shouldDisplay = () => {
    if (
      token === "99b5a98543d338715d99c97af59b58228f504f91" ||
      token === "464fa15cebb908e3cf96dbf4740d74aea71b76f4" ||
      token === "8cc5c148d15012868ac5f4f6f610cfb304758f95"
    ) {
      // Hide store status for alanmancenissan and wheelsanddeals
      return false;
    }
    return true;
  };

  const isStoreOpenNow = useCallback(
    () =>
      isStoreOpen({
        organizationTimeZone,
        organizationWorkingHours,
        organizationCustomWorkingHours,
      }),
    [
      organizationTimeZone,
      organizationWorkingHours,
      organizationCustomWorkingHours,
    ]
  );

  const getStoreOpenLabel = () => {
    if (token === "9f62d83fab2eda5c76dd67f9e3f9aaf5dafc10f0") {
      return strings.OPEN_STORE_SD;
    }
    return strings.OPEN_STORE;
  };

  const label = isStoreOpenNow() ? getStoreOpenLabel() : strings.CLOSED_STORE;

  if (!shouldDisplay()) {
    return <div style={{ marginTop: 10 }} />;
  }

  return (
    <Container>
      <Circle
        backgroundColor={isStoreOpenNow() ? "#50C08D" : "#F1361C"}
      ></Circle>
      <Label color={secondaryColor}>{label}</Label>
    </Container>
  );
};

export default StoreStatus;
