import React, { useState, useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { strings } from "../../utils/localization";

const PhoneNumberField = ({
  value,
  buttonStyle,
  onChange,
  defaultCountryFlag,
  getPhoneNumberFromClipboard,
  onFocus,
  onBlur,
  placeholder,
  borderRadius = 27,
}) => {
  const styles = {
    buttonStyle: {
      backgroundColor: "#F4F7FC",
      border: 0,
      ...buttonStyle,
    },
  };
  const [fieldTouched, setFieldTouched] = useState(false);

  const getValue = () => {
    if (defaultCountryFlag?.toLowerCase() === "au" && !value && !fieldTouched) {
      return "+04";
    }
    return value;
  };

  const onChangeHandler = (value) => {
    setFieldTouched(true);
    if (defaultCountryFlag?.toLowerCase() === "au" && !value && !fieldTouched) {
      return onChange(`+04${value}`);
    }
    return onChange(value);
  };

  useEffect(() => {
    document.querySelectorAll(".matadot-live-chat-123789-main-input")[0] &&
      document
        .querySelectorAll(".matadot-live-chat-123789-main-input")[0]
        .addEventListener("paste", getPhoneNumberFromClipboard);
  }, []);

  return (
    <PhoneInput
      country={defaultCountryFlag || "us"}
      inputClass="matadot-live-chat-123789-main-input"
      onlyCountries={["us", "ca", "au"]}
      countryCodeEditable
      value={getValue()}
      buttonStyle={styles.buttonStyle}
      placeholder={placeholder || strings.MOBILE_PHONE}
      onChange={onChangeHandler}
      dropdownStyle={{ bottom: 35, borderRadius: 4 }}
      inputStyle={{ borderRadius }}
      disableDropdown
      disableCountryCode={defaultCountryFlag?.toLowerCase() === "au"}
      masks={{ au: "(..) .... ...." }}
      onFocus={onFocus}
      onBlur={onBlur}
    />
  );
};

export default PhoneNumberField;
