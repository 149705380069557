import React from "react";
import styled from "styled-components";
import { Iconset } from "../../../components/Icons/Icons";
import { ReactComponent as SuccessIcon } from "./assets/check-icon.svg";
import { strings } from "../../../utils/localization";
import AppointmentBooking from "../Appointment/AppointmentBooking";
import Linkify from "linkify-react";

const MessageContainer = styled.div`
  display: flex;
  justify-content: ${({ isSent }) => (isSent ? "flex-end" : "flex-start")};
  align-items: flex-end;
  margin-bottom: 10px;
`;

const Avatar = styled.img`
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  border-radius: 16px;
  z-index: 2;
  object-fit: cover;
  background-color: #f9f9f9;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DefaultAvatar = styled.div`
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  border-radius: 16px;
  background-color: #f9f9f9;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const EmptyAvatar = styled.div`
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  border-radius: 16px;
  margin-right: 10px;
`;

const AvatarContainer = styled.div`
  margin-right: 10px;
`;

const RightMessageContainer = styled.div`
  display: flex;
  padding: 10px;
  flex-direction: column;
  align-items: flex-end;
  border-radius: 12px 12px 0px 12px;
  width: fit-content;
`;

const RightContainer = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  padding-left: 40px;
`;

const LeftContainer = styled.div`
  display: flex;
  align-items: flex-end;
  padding-right: 15px;
  position: relative;
  width: 100%;

  animation: slideMatadorAnimation 0.5s forwards;

  @keyframes slideMatadorAnimation {
    0% {
      left: -50px;
    }
    100% {
      left: 0;
    }
  }
`;

const LeftMessageContainer = styled.div`
  display: flex;
  padding: 10px;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  border-radius: 12px 12px 12px 0px;
  border: 1px solid #cdd9ea;
  max-width: 80%;
  max-width: 80%;
`;

const MessageText = styled.p`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  margin: 0;
  word-break: break-word;
  color: ${(props) => props.color};
`;

const Status = styled.div`
  color: #7e7e7e;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3px;
`;

const Text = styled.span`
  margin-left: 2px;
`;

const Icon = styled.div`
  margin-bottom: 1px;
`;

const FormWrapper = styled.div`
  margin-top: 10px;
`;

const TimeStamp = styled.div`
  font-size: 10px;
  font-weight: 400;
  color: ${(props) => props.color};
  opacity: 0.6;
  margin-top: 4px;
`;

const Message = ({
  message,
  avatarImg,
  secondaryColor,
  timeColor,
  color,
  timestamp,
  SubmitForm,
  nextIsSent,
  organizationTimeZone,
  organizationWorkingHours,
  organizationCustomWorkingHours,
  hideAppointmentBooking,
  submitBooking,
}) => {
  const { text, role, type } = message;
  const isSent = role === "user";

  const renderAvatar = () => {
    if (isSent) return null;

    if (!isSent && nextIsSent === false) return <EmptyAvatar />;

    let content = null;

    if (avatarImg) {
      content = <Avatar src={avatarImg} alt="avatar" />;
    } else {
      content = <DefaultAvatar>{Iconset.noImageDefault}</DefaultAvatar>;
    }

    return <AvatarContainer>{content}</AvatarContainer>;
  };

  const renderText = () => {
    if (type === "user_form") {
      return (
        <div>
          <LeftContainer>
            <LeftMessageContainer>
              <MessageText color="#222">{text}</MessageText>
            </LeftMessageContainer>
          </LeftContainer>
          <FormWrapper>{SubmitForm()}</FormWrapper>
        </div>
      );
    }
    if (type === "booking") {
      return (
        <div>
          <LeftMessageContainer>
            <MessageText color="#222">{text}</MessageText>
          </LeftMessageContainer>
          <AppointmentBooking
            color={color}
            organizationTimeZone={organizationTimeZone}
            organizationWorkingHours={organizationWorkingHours}
            organizationCustomWorkingHours={organizationCustomWorkingHours}
            hideAppointmentBooking={hideAppointmentBooking}
            submitBooking={submitBooking}
          />
        </div>
      );
    }

    if (isSent) {
      return (
        <RightContainer>
          <RightMessageContainer style={{ backgroundColor: color }}>
            <MessageText color={secondaryColor}>{text}</MessageText>
            <TimeStamp color={secondaryColor}>{timestamp}</TimeStamp>
          </RightMessageContainer>
          <Status>
            <Icon>
              <SuccessIcon />
            </Icon>
            <Text>{strings.DELIVERED}</Text>
          </Status>
        </RightContainer>
      );
    } else {
      return (
        <LeftContainer>
          <LeftMessageContainer>
            <MessageText color="#222">
              <Linkify options={{ target: "_blank" }}>{text}</Linkify>
            </MessageText>
          </LeftMessageContainer>
        </LeftContainer>
      );
    }
  };

  return (
    <MessageContainer isSent={isSent}>
      {renderAvatar()}
      {renderText()}
    </MessageContainer>
  );
};

export default Message;
