import axios from "axios";
import { urlConfig } from "../components/modules/UrlHelper";

class QuestionsApi {
  static sendQuestion = ({ itemId, param }) => {
    return axios({
      method: "POST",
      // TODO: UPDATE URL
      url: `${urlConfig.BASE_URL}/triggers/${itemId}`,
      data: param,
    });
  };
  static textMe = ({ locationId, param }) => {
    return axios({
      method: "POST",
      url: `${urlConfig.BASE_URL}/text-us/${locationId}/message/create`,
      data: param,
    });
  };
  static exitIntent = ({ locationId, param }) => {
    return axios({
      method: "POST",
      url: `${urlConfig.BASE_URL}/smart-promotions/${locationId}/message/create`,
      data: param,
    });
  };
  static desktopCoupon = ({ locationId, param }) => {
    return axios({
      method: "POST",
      url: `${urlConfig.BASE_URL}/coupons/${locationId}/message/create`,
      data: param,
    });
  };
  static subscriberGrowth = ({ locationId, param }) => {
    return axios({
      method: "POST",
      url: `${urlConfig.BASE_URL}/subscriber-growth/${locationId}/message/create`,
      data: param,
    });
  };
}

export default QuestionsApi;
