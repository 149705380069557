import React, { useState } from "react";

const MessagingDebug = ({ logs, chatId }) => {
  const [showLog, setShowLog] = useState(false);

  return (
    <div
      style={{
        borderRadius: "5px",
      }}
    >
      <span
        style={{
          opacity: 0.2,
        }}
        onClick={() => setShowLog((x) => !x)}
      >
        _
      </span>

      {showLog && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 1000,
            background: "gray",
            color: "white",
            overflow: "auto",
            padding: 10,
          }}
        >
          <p onClick={() => setShowLog(false)}>Hide log</p>
          <div style={{ marginBottom: "10px", fontWeight: "bold" }}>
            Chat ID: {chatId}
          </div>
          <div>
            <pre style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
              {JSON.stringify(logs, null, 2)}
            </pre>
          </div>
        </div>
      )}
    </div>
  );
};

export default MessagingDebug;
